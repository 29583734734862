import { Widget } from "react-jsonschema-form-bs4";
import AgreeTermsAndConditions from "./widgets/AgreeTermsAndConditions";
import Checkboxes from "./widgets/Checkboxes";
import Currency from "./widgets/Currency";
import Date from "./widgets/Date";
import DateTime from "./widgets/DateTime";
import HtmlEditor from "./widgets/HtmlEditor";

const widgets: { [name: string]: Widget } = {
  // @ts-ignore
  AgreeTermsAndConditions,
  // @ts-ignore
  Checkboxes,
  Currency,
  Date,
  DateTime,
  HtmlEditor
};

export default widgets;
