import { JSONSchema6 } from "json-schema";
import { php } from "locutus";
import moment from "moment-timezone";
import { OpenAPIV3 } from "openapi-types";
import React from "react";
import Form from "react-jsonschema-form-bs4";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import axios from "../../../../inc/axios";
import quoteEventUiSchema from "../../../../resources/schemas/QuoteEvent.ui.json";
import { IRootState } from "../../../../store";
import { setEvent } from "../../../../store/event";
import {
  addEvent,
  update,
  updateFormData
} from "../../../../store/quoteRequest";
import widgets from "../../../../view/components/Form/widgets";

interface IStateProps {
  events: {
    [calendarId: string]: { [eventId: string]: gapi.client.calendar.Event };
  };
  quoteRequest: Components.Schemas.QuoteRequest;
  quoteEventSchema?: OpenAPIV3.NonArraySchemaObject;
}

const stateToProps = (state: any) => ({
  events: state.event.events,
  quoteRequest: state.quoteRequest.formData,
  quoteEventSchema: state.schema.openApiDoc.components.schemas
    .QuoteEvent as OpenAPIV3.NonArraySchemaObject
});

interface IDispatchProps {
  addEvent: typeof addEvent;
  setEvent: typeof setEvent;
  update: typeof update;
  updateFormData: typeof updateFormData;
}

const dispatchToProps: IDispatchProps = {
  addEvent,
  setEvent,
  update,
  updateFormData
};

interface IQuoteEventFormState {
  errors?: Error[];
  formDirty: boolean;
}

interface IOwnProps {
  possiblyUpdateEditor?: (
    quoteRequest: Components.Schemas.QuoteRequest
  ) => void;
}

const {
  url: { urlencode }
} = php;

type QuoteEventFormProps = IStateProps & IDispatchProps & IOwnProps;

const getFormData = (
  event?: gapi.client.calendar.Event & { eventId?: string }
) => ({
  id: event && event.eventId ? event.eventId : "",
  start: event && event.start ? event.start.dateTime : "",
  end: event && event.end ? event.end.dateTime : ""
});

class QuoteEventForm extends React.Component<
  QuoteEventFormProps,
  IQuoteEventFormState
> {
  public state: IQuoteEventFormState = {
    formDirty: false
  };

  public onAddEventClick = () => {
    this.props.addEvent(this.props.quoteRequest);
  };

  public render() {
    const { events, quoteRequest, quoteEventSchema } = this.props;

    if (!quoteRequest || !quoteEventSchema) {
      return null;
    }

    const event: gapi.client.calendar.Event | null =
      quoteRequest.calendarId &&
      quoteRequest.eventId &&
      events[quoteRequest.calendarId]
        ? events[quoteRequest.calendarId][quoteRequest.eventId]
        : null;

    return event ? (
      <Form
        schema={quoteEventSchema as JSONSchema6}
        uiSchema={quoteEventUiSchema}
        widgets={widgets}
        className="quoteEventForm"
        onChange={this.onEventChange}
        // https://github.com/mozilla-services/react-jsonschema-form/issues/486
        key={JSON.stringify(event)}
        formData={getFormData(event)}
        liveValidate={true}
        validate={this.eventValidate}
      >
        <span />
      </Form>
    ) : (
      <Button
        color="primary"
        onClick={this.onAddEventClick}
        style={{ marginBottom: 8 }}
      >
        Optie toevoegen aan agenda
      </Button>
    );
  }

  private onEventChange = ({ errors, formData: eventFormData }: any) => {
    const { events, quoteRequest } = this.props;
    const event: gapi.client.calendar.Event | null =
      quoteRequest.calendarId &&
      quoteRequest.eventId &&
      events[quoteRequest.calendarId]
        ? events[quoteRequest.calendarId][quoteRequest.eventId]
        : null;
    const originalEvent = event || {
      start: { dateTime: "" },
      end: { dateTime: "" }
    };
    const putEvent = {
      // Prevent Error: Invalid sequence value.
      // ...originalEvent,
      start: { dateTime: eventFormData.start },
      end: { dateTime: eventFormData.end }
    };

    if (
      originalEvent.start &&
      originalEvent.end &&
      eventFormData.start !== originalEvent.start.dateTime
    ) {
      putEvent.end.dateTime = moment(eventFormData.start)
        .add(
          Math.max(
            moment(originalEvent.end.dateTime).diff(
              originalEvent.start.dateTime
            ) / 1000,
            3600
          ),
          "seconds"
        )
        .format();
      eventFormData.end = putEvent.end.dateTime;
      if (errors[0] && errors[0].stack === "end: Eind is voor begin") {
        errors.shift();
      }
    }

    const { calendarId, eventId } = this.props.quoteRequest;

    if (errors.length === 0) {
      axios
        .put(
          `/api/google/calendar/${urlencode(calendarId)}/${urlencode(eventId)}`,
          putEvent
        )
        .then((res: any) => {
          this.props.setEvent(
            calendarId as string,
            eventId as string,
            res.data
          );
          if (this.props.possiblyUpdateEditor) {
            this.props.possiblyUpdateEditor(quoteRequest);
          }
        });
    }
  };

  private eventValidate = (eventFormData: any, errors: any) => {
    if (!moment(eventFormData.end).isAfter(eventFormData.start)) {
      errors.end.addError("Eind is voor begin");
    }
    return errors;
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
  stateToProps,
  dispatchToProps
)(QuoteEventForm as any);
