import axios from "../inc/axios";

export function hydrateContext() {
  return (dispatch, getState) => {
    const state = getState();
    if (state.sheet.context !== undefined) {
      return;
    }

    dispatch({
      type: "SHEET_HYDRATE_CONTEXT",
      payload: axios.get("/api/sheetContext").then(res => res.data)
    });
  };
}

const initialState = {
  context: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SHEET_HYDRATE_CONTEXT":
      switch (action.status) {
        case "pending":
          return { ...state, context: null };
        case "success":
          return { ...state, context: action.payload };
        default:
      }
      break;
    default:
  }
  return state;
};
