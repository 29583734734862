import React from "react";
import { Editor } from "@tinymce/tinymce-react";

interface IHtmlEditorProps {
  onChange: any;
  value: string;
}

export default (props: IHtmlEditorProps) => (
  <Editor
    value={props.value}
    init={{
      relative_urls: false,
      remove_script_host: false,
      document_base_url: "https://app.outstandingevents.nl/",
      height: 500,
      menubar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount"
      ],
      toolbar:
        // eslint-disable-next-line no-multi-str
        "undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help"
    }}
    onEditorChange={props.onChange}
  />
);
