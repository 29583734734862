import { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import Notifications from "react-notification-system-redux";
import { Action, AnyAction, Store } from "redux";
import { IRootState } from "..";

const messages: { [key: string]: string } = {
  Forbidden: "Controleer uw inloggegevens",
  "Password Expired": "Wachtwoord verlopen, nieuwe wachtwoord invoeren"
};

export interface IPromiseAction<T = any> extends Action {
  status?: "pending" | "success" | "error";
  payload:
    | AxiosPromise<T>
    | AxiosResponse<T>
    | AxiosError
    | Promise<T | AxiosResponse<T>>
    | T
    | Error;
  showRejectMessage?: boolean;
}

const middleware = function promiseMiddleware(store: Store<IRootState>) {
  const dispatch = store.dispatch;

  return (next: (action: AnyAction) => void) => (
    action: Action & IPromiseAction
  ) => {
    if (!action.payload || !action.payload.then) {
      return next(action);
    }

    dispatch({
      ...action,
      status: "pending",
      promise: action.payload,
      payload: null
    });
    return action.payload.then(
      (result: any) => {
        dispatch({ ...action, status: "success", payload: result });
        return result;
      },
      (error: AxiosError | Error) => {
        dispatch({ ...action, status: "error", payload: error });
        const { response } = error as AxiosError;
        let message = (response && response.data) || error.message;
        if (typeof message !== "string") {
          message = JSON.stringify(message);
        }
        if (action.showRejectMessage !== false) {
          dispatch(
            Notifications.error({
              title: "Er gaat iets mis!",
              message: messages[message] || message,
              position: "tc",
              autoDismiss: 5
            })
          );
        }
        // we need to throw it again: Promise resolution results may be used
        throw error;
      }
    );
  };
};

export default middleware;
