import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import Spinner from "react-spinkit";

import axios from "../../inc/axios";
import { IRootState } from "../../store";
import { checkAuthenticated } from "../../store/auth";
import Header from "../components/Header";
import QuoteRequestPage from "../page/QuoteRequest";
import QuoteRequestsPage from "../page/QuoteRequests";
import TemplatePage from "../page/Template";
import TemplatesPage from "../page/Templates";

interface IStateProps {
  pending: boolean;
  google: boolean;
}

const stateToProps = (state: IRootState): IStateProps => ({
  pending: state.auth.pending,
  google: state.auth.google
});

interface IDispatchProps {
  checkAuthenticated: typeof checkAuthenticated;
}

const dispatchToProps: IDispatchProps = { checkAuthenticated };

type MainProps = IStateProps & IDispatchProps;

class Main extends Component<MainProps> {
  public componentDidMount() {
    if (!this.props.google) {
      this.props.checkAuthenticated();
    }
  }

  public componentWillReceiveProps(props: MainProps) {
    // ready with auth, but not logged in?
    if (this.props.pending && !props.pending && !props.google) {
      window.location.href = `${
        axios.defaults.baseURL
      }/login?redirect=${encodeURIComponent(window.location.pathname)}`;
    }
  }

  public render() {
    if (!this.props.google) {
      return <Spinner name="pacman" style={{ margin: 20, marginLeft: 60 }} />;
    }

    return (
      <div className="main">
        <Header />
        <div className="app-body d-flex">
          <Route path="/quoteRequests" component={QuoteRequestsPage} />
          <Route path="/templates" component={TemplatesPage} />
          <Route
            path="/template/:templateId"
            component={TemplatePage}
            exact={true}
          />
          <Route path="/template" component={TemplatePage} exact={true} />
          <Route
            path="/quoteRequest/:quoteRequestId"
            component={QuoteRequestPage}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  stateToProps,
  dispatchToProps
)(Main);
