import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Spinner from "react-spinkit";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import { IRootState } from "../../../store";
import { hydrate, update as updateTemplate } from "../../../store/template";
import { Editor } from "@tinymce/tinymce-react";

interface IStateProps {
  templates?: null | Components.Schemas.Template[];
}

const stateToProps = (state: IRootState): IStateProps => ({
  templates: state.template.data
});

interface IDispatchProps {
  hydrate: () => void;
  updateTemplate: typeof updateTemplate;
}

const dispatchToProps = { hydrate, updateTemplate };

type TTemplatePageProps = IStateProps &
  IDispatchProps &
  RouteComponentProps<{ templateId?: string }>;

interface ITemplatePageState {
  template?: Components.Schemas.Template;
}

class TemplatePage extends React.Component<
  TTemplatePageProps,
  ITemplatePageState
> {
  constructor(props: TTemplatePageProps) {
    super(props);
    this.state = this.getNewState(props) || {};
  }

  public componentDidMount() {
    this.props.hydrate();
  }

  public componentWillReceiveProps(nextProps: TTemplatePageProps) {
    const newState = this.getNewState(nextProps);
    if (newState) {
      this.setState(newState);
    }
  }

  public render() {
    const { template } = this.state;
    if (!template) {
      return <Spinner name="pacman" style={{ margin: 20, marginLeft: 60 }} />;
    }
    return (
      <Card className="flex-grow-1">
        <CardHeader>
          <strong>Sjabloon</strong>
        </CardHeader>
        <CardBody>
          <Form>
            <FormGroup>
              <Label for="title">Naam</Label>
              <Input
                name="title"
                id="title"
                value={template.title}
                onChange={e => {
                  this.updateTemplate({ title: e.currentTarget.value });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="template">Sjabloon</Label>
              <Editor
                value={template.html || ""}
                init={{
                  relative_urls: false,
                  remove_script_host: false,
                  document_base_url: "https://app.outstandingevents.nl/",
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount"
                  ],
                  toolbar:
                    // eslint-disable-next-line no-multi-str
                    "undo redo | formatselect | bold italic forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help"
                }}
                onEditorChange={html => {
                  this.updateTemplate({ html });
                }}
              />
            </FormGroup>
          </Form>
          <Button color="primary" onClick={this.onTemplateSave}>
            Opslaan
          </Button>
        </CardBody>
      </Card>
    );
  }

  private getNewState(props: TTemplatePageProps): ITemplatePageState | null {
    const { templates } = props;
    if (!templates) {
      return null;
    }
    if (!props.match.params.templateId) {
      return this.state?.template
        ? null
        : {
            template: {
              title: "",
              html: ""
            }
          };
    }
    const template = props.match.params.templateId
      ? templates.find(obj => obj._id === props.match.params.templateId)
      : {
          title: "",
          html: ""
        };
    if (template && template._id !== this.state?.template?._id) {
      return {
        template
      };
    }
    return null;
  }

  private updateTemplate(props: Partial<Components.Schemas.Template>) {
    const { template } = this.state;
    if (!template) {
      return;
    }
    this.setState({
      template: {
        ...template,
        ...props
      }
    });
  }

  private onTemplateSave = () => {
    const { template } = this.state;
    if (!template) {
      return;
    }
    this.props.updateTemplate({
      ...template
    });
    this.props.history.push("/templates");
  };
}

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
  stateToProps,
  dispatchToProps
)(TemplatePage);
