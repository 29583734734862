import moment from "moment-timezone";
import React from "react";
import DatePicker from "react-datepicker";
import { Widget, WidgetProps } from "react-jsonschema-form-bs4";

import "./index.scss";

class DateWidget extends React.Component<WidgetProps> {
  public render() {
    return (
      <div className="components__form__widgets__date d-flex">
        <DatePicker
          className="form-control"
          selected={
            this.props.value ? moment(this.props.value).toDate() : undefined
          }
          onChange={this.onChange}
          dateFormat="dd-MM-yyyy"
        />
      </div>
    );
  }

  private onChange = (newDate: Date) => {
    this.props.onChange(
      newDate
        ? moment(newDate)
            .format()
            .substr(0, 10)
        : undefined
    );
  };
}

export default DateWidget as Widget;
