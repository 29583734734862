import { Promise } from "bluebird";
import { php } from "locutus";
import { Action, Dispatch } from "redux";

import axios from "../inc/axios";
import { ActionTypes } from "./index";
import { IPromiseAction } from "./middleware/promise";

const {
  url: { urlencode }
} = php;

interface IHydrateAction extends IPromiseAction<gapi.client.calendar.Event> {
  type: ActionTypes.EVENT_HYDRATE;
}

export const hydrateEvents = (
  quoteRequests: Components.Schemas.QuoteRequest[]
) => (dispatch: Dispatch) =>
  Promise.map(
    quoteRequests,
    (quoteRequest: Components.Schemas.QuoteRequest) => {
      const { calendarId, eventId } = quoteRequest;
      if (calendarId && eventId) {
        dispatch(hydrateEvent(calendarId, eventId));
      }
      return quoteRequest;
    },
    { concurrency: 8 }
  );

export const hydrateEvent = (calendarId: string, eventId: string) => ({
  type: ActionTypes.EVENT_HYDRATE,
  showRejectMessage: false,
  payload: axios
    .get(`/api/google/calendar/${urlencode(calendarId)}/${urlencode(eventId)}`)
    .then((res: any) => ({
      ...res.data,
      calendarId,
      eventId
    }))
});

interface IHydrateUpcomingAction
  extends IPromiseAction<gapi.client.calendar.Event[]> {
  type: ActionTypes.EVENT_HYDRATE_UPCOMING;
}

export const hydrateUpcoming = (): IHydrateUpcomingAction => ({
  type: ActionTypes.EVENT_HYDRATE_UPCOMING,
  payload: axios
    .get(`/api/google/calendar/upcomingBookingEvents`)
    .then((res: any) => res.data)
});

interface ISetAction extends Action {
  type: ActionTypes.EVENT_SET;
  calendarId: string;
  eventId: string;
  event: gapi.client.calendar.Event;
}

export const setEvent = (
  calendarId: string,
  eventId: string,
  event: gapi.client.calendar.Event
) => ({
  calendarId,
  event,
  eventId,
  type: ActionTypes.EVENT_SET
});

export interface IEventState {
  events: {
    [calendarId: string]: { [eventId: string]: gapi.client.calendar.Event };
  };
  upcomingOrderEvents?: gapi.client.calendar.Event[];
}

const defaultState: IEventState = {
  events: {}
};

export function reducer(
  state = defaultState,
  action: IHydrateAction | IHydrateUpcomingAction | ISetAction
): IEventState {
  switch (action.type) {
    case ActionTypes.EVENT_HYDRATE:
      switch ((action as IHydrateAction).status) {
        case "success":
          const hydratedEvent = (action as IHydrateAction)
            .payload as gapi.client.calendar.Event & {
            eventId: string;
            calendarId: string;
          };
          return {
            ...state,
            events: {
              ...state.events,
              [hydratedEvent.calendarId]: {
                ...state.events[hydratedEvent.calendarId],
                [hydratedEvent.eventId]: hydratedEvent
              }
            }
          };
      }
      break;

    case ActionTypes.EVENT_HYDRATE_UPCOMING:
      switch ((action as IHydrateUpcomingAction).status) {
        case "success":
          return {
            ...state,
            upcomingOrderEvents: action.payload as gapi.client.calendar.Event[]
          };
      }
      break;

    case ActionTypes.EVENT_SET:
      const { calendarId, eventId, event } = action as ISetAction;
      return {
        ...state,
        events: {
          ...state.events,
          [calendarId]: {
            ...state.events[calendarId],
            [eventId]: event
          }
        }
      };
  }
  return state;
}
