import React from "react";
import { Button, Col, Row } from "reactstrap";
// @ts-ignore
// tslint:disable-next-line
import CreatableSelect from "react-select/lib/Creatable";

interface IProgramLineRowProps {
  index: number;
  programLine: Components.Schemas.ProgramLine;
  onChange: (
    index: number,
    programLine: Components.Schemas.ProgramLine
  ) => void;
  onDelete: (index: number) => void;
}

export default class ProgramLineRow extends React.Component<
  IProgramLineRowProps
> {
  public render() {
    const timeOptions = [
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "13:00",
      "13:15",
      "13:30",
      "13:45",
      "14:00",
      "14:15",
      "14:30",
      "14:45",
      "15:00",
      "15:15",
      "15:30",
      "15:45",
      "16:00",
      "16:15",
      "16:30",
      "16:45",
      "17:00",
      "17:15",
      "17:30",
      "17:45",
      "18:00",
      "18:15",
      "18:30",
      "18:45",
      "19:00",
      "19:15",
      "19:30",
      "19:45",
      "20:00",
      "20:15",
      "20:30",
      "20:45"
    ];
    const descriptionOptions = [
      "ontvangst",
      "start activiteiten",
      "pauze",
      "vervolg programma",
      "einde activiteiten",
      "borrelen",
      "diner aan zee",
      "einde"
    ];

    return (
      <Row className="mb-2">
        <Col style={{ flex: "0 0 160px" }}>
          <CreatableSelect
            onChange={this.handleTimeChange}
            options={timeOptions.map(timeOption => ({
              value: timeOption,
              label: timeOption
            }))}
            placeholder="Tijd"
            value={{
              value: this.props.programLine.time,
              label: this.props.programLine.time
            }}
            name={`time_${this.props.index}`}
          />
        </Col>
        <Col>
          <CreatableSelect
            onChange={this.handleDescriptionChange}
            options={descriptionOptions.map(descriptionOption => ({
              value: descriptionOption,
              label: descriptionOption
            }))}
            placeholder="Omschrijving"
            value={{
              value: this.props.programLine.description,
              label: this.props.programLine.description
            }}
            name={`description_${this.props.index}`}
          />
        </Col>
        <Col style={{ flex: "0 0 32px" }}>
          <Button color="danger" onClick={this.onDeleteClick}>
            X
          </Button>
        </Col>
      </Row>
    );
  }

  private handleTimeChange = (newValue: any, actionMeta: any) => {
    if (!newValue.value) {
      return;
    }
    this.props.onChange(this.props.index, {
      ...this.props.programLine,
      time: newValue.value
    });
  };

  private handleDescriptionChange = (newValue?: any, actionMeta?: any) => {
    if (!newValue.value) {
      return;
    }
    this.props.onChange(this.props.index, {
      ...this.props.programLine,
      description: newValue.value
    });
  };

  private onDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.props.onDelete(this.props.index);
  };
}
