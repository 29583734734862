import moment from "moment-timezone";
import React from "react";
import { Field, FieldProps } from "react-jsonschema-form-bs4";
import { connect } from "react-redux";
import { Button } from "reactstrap";

import ProgramLineRow from "./ProgramField/ProgramLineRow";

import { IRootState } from "../../../../store";

interface IStateProps {
  events: {
    [calendarId: string]: { [eventId: string]: gapi.client.calendar.Event };
  };
  quoteRequest?: Components.Schemas.QuoteRequest;
}

const stateToProps = (state: IRootState): IStateProps => ({
  events: state.event.events,
  quoteRequest: state.quoteRequest.formData
});

class ProgramField extends React.Component<FieldProps & IStateProps> {
  public render() {
    const { formData = [] } = this.props;
    return (
      <fieldset>
        <legend>
          Programma
          <Button
            color="secondary"
            size="sm"
            className="mx-1"
            onClick={this.onRefreshProgramClick}
            disabled={this.isFresh()}
          >
            Verversen
          </Button>
        </legend>
        {formData && formData.length
          ? formData.map(
              (programLine: Components.Schemas.ProgramLine, key: number) => (
                <ProgramLineRow
                  key={key}
                  index={key}
                  programLine={programLine}
                  onChange={this.onRowChange}
                  onDelete={this.onRowDelete}
                />
              )
            )
          : "Nog geen programma bekend"}
        <Button color="success" onClick={this.onAddClick}>
          +
        </Button>
      </fieldset>
    );
  }

  private getEvent(): gapi.client.calendar.Event | null {
    const { events, quoteRequest } = this.props;
    if (!quoteRequest) {
      return null;
    }

    return quoteRequest.calendarId &&
      quoteRequest.eventId &&
      events[quoteRequest.calendarId]
      ? events[quoteRequest.calendarId][quoteRequest.eventId]
      : null;
  }

  private isFresh() {
    const event = this.getEvent();
    if (!event) {
      return true;
    }
    return (
      JSON.stringify(this.getDefaultProgram(event)) ===
      JSON.stringify(this.props.formData)
    );
  }

  private getDefaultProgram(
    event: gapi.client.calendar.Event
  ): Components.Schemas.ProgramLine[] {
    const program: Components.Schemas.ProgramLine[] = [];
    if (!event.start) {
      return program;
    }
    const programLineMoment = moment(event.start.dateTime);
    programLineMoment.subtract(45, "minutes");
    program.push({
      time: programLineMoment.format("HH:mm"),
      description: "ontvangst"
    });
    programLineMoment.add(45, "minutes");
    program.push({
      time: programLineMoment.format("HH:mm"),
      description: "start activiteiten"
    });
    programLineMoment.add(2, "hours");
    program.push({
      time: programLineMoment.format("HH:mm"),
      description: "pauze"
    });
    programLineMoment.add(30, "minutes");
    program.push({
      time: programLineMoment.format("HH:mm"),
      description: "vervolg programma"
    });
    programLineMoment.add(1, "hour");
    program.push({
      time: programLineMoment.format("HH:mm"),
      description: "einde activiteiten"
    });
    programLineMoment.add(15, "minutes");
    program.push({
      time: programLineMoment.format("HH:mm"),
      description: "borrelen"
    });
    programLineMoment.add(30, "minutes");
    program.push({
      time: programLineMoment.format("HH:mm"),
      description: "diner aan zee"
    });
    programLineMoment.add(2, "hours");
    program.push({
      time: programLineMoment.format("HH:mm"),
      description: "einde"
    });

    return program;
  }

  private onRefreshProgramClick = (e: React.MouseEvent) => {
    const event = this.getEvent();
    if (!event) {
      return;
    }
    this.props.onChange(this.getDefaultProgram(event));
  };

  private onRowChange = (
    index: number,
    updatedProgramLine: Components.Schemas.ProgramLine
  ) => {
    this.props.onChange(
      this.props.formData
        .map((programLine: Components.Schemas.ProgramLine, key: number) =>
          key === index ? updatedProgramLine : programLine
        )
        .sort(
          (
            a: Components.Schemas.ProgramLine,
            b: Components.Schemas.ProgramLine
          ) => (a.time < b.time ? -1 : 1)
        )
    );
  };

  private onAddClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { formData = [] } = this.props;
    const lastTime =
      formData && formData.length
        ? this.props.formData[this.props.formData.length - 1].time
        : "12:00";

    this.props.onChange([
      ...formData,
      {
        time: moment(lastTime, "HH:mm")
          .add(15, "minutes")
          .format("HH:mm"),
        description: ""
      }
    ]);
  };

  private onRowDelete = (toBeRemovedKey: number) => {
    const { formData = [] } = this.props;
    this.props.onChange(
      formData.filter(
        (programLine: Components.Schemas.ProgramLine, key: number) =>
          key !== toBeRemovedKey
      )
    );
  };
}

export default connect<IStateProps, {}, FieldProps, IRootState>(stateToProps)(
  ProgramField
) as Field;
