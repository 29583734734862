import { AxiosError } from "axios";
import { JSONSchema6 } from "json-schema";
import { debounce, difference, uniq } from "lodash";
import moment from "moment-timezone";
import { OpenAPIV3 } from "openapi-types";
import React from "react";
import Form from "react-jsonschema-form-bs4";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import axios from "../../../../inc/axios";
import { transformErrors } from "../../../../inc/form";
import quoteRequestUiSchema from "../../../../resources/schemas/QuoteRequest.ui.json";
import { getStore, IRootState } from "../../../../store";
import { ILogisticsItem } from "../../../../store/logisticsItem";
import { updateFormData } from "../../../../store/quoteRequest";
import fields from "../../../../view/components/Form/fields";
import widgets from "../../../../view/components/Form/widgets";

import "./index.scss";

// @ts-ignore
/* tslint:disable-next-line */
const reactJsonschemaFormUtils = require("react-jsonschema-form-bs4/lib/utils");

function getLocationCalendarId(
  locationId?: Components.Schemas.Location
): string {
  if (process.env.NODE_ENV !== "production") {
    return "flsdr2sv3i542r02k8mdrjomi0@group.calendar.google.com";
  }

  switch (locationId) {
    case "Aloha":
      return "8ii1gg9omel33cpf24q1js9q0k@group.calendar.google.com";

    case "Outstanding":
      return "9mqh4hob0mgflv3p4emvravij0@group.calendar.google.com";

    case "Timboektoe":
      return "pkr4rnmfsgbluv30ag7p267e4o@group.calendar.google.com";

    case "Zuidpier":
      return "algfocprp36dpdi19ggj11i288@group.calendar.google.com";
  }

  return "ss20ls569ms4cuuqo21fjvvqg8@group.calendar.google.com";
}

interface IStateProps {
  events: {
    [calendarId: string]: { [eventId: string]: gapi.client.calendar.Event };
  };
  logisticsItems?: ILogisticsItem[];
  quoteRequest?: Components.Schemas.QuoteRequest;
  openApiDoc?: OpenAPIV3.Document | null;
}

const stateToProps = (state: IRootState): IStateProps => ({
  events: state.event.events,
  logisticsItems: state.logisticsItem.data,
  quoteRequest: state.quoteRequest.formData,
  openApiDoc: state.schema.openApiDoc
});

interface IDispatchProps {
  updateFormData: typeof updateFormData;
}

const dispatchToProps: IDispatchProps = {
  updateFormData
};

interface IOwnProps {
  formDirty: boolean;
  persistFormData: (
    quoteRequest: Components.Schemas.QuoteRequest | null,
    dirty?: boolean
  ) => void;
}

type QuoteRequestFormProps = IStateProps & IDispatchProps & IOwnProps;

class QuoteRequestForm extends React.Component<QuoteRequestFormProps> {
  private debouncedPersist: (
    quoteRequest: Components.Schemas.QuoteRequest | null,
    dirty: boolean
  ) => void;

  constructor(props: QuoteRequestFormProps) {
    super(props);
    this.debouncedPersist = debounce(this.props.persistFormData, 1000);
  }

  public render() {
    const { logisticsItems = [], quoteRequest, openApiDoc } = this.props;
    const quoteRequestSchema = openApiDoc?.components?.schemas
      ?.QuoteRequest as OpenAPIV3.NonArraySchemaObject;
    const locationSchema = openApiDoc?.components?.schemas
      ?.Location as OpenAPIV3.NonArraySchemaObject;

    const originalExtraInvoiceLinesSchema = quoteRequestSchema.properties
      ?.extraInvoiceLines as OpenAPIV3.ArraySchemaObject & {
      items: OpenAPIV3.NonArraySchemaObject;
    };
    const schema: any = {
      ...quoteRequestSchema,
      properties: {
        ...quoteRequestSchema.properties,
        extraInvoiceLines: {
          ...quoteRequestSchema.properties?.extraInvoiceLines,
          items: {
            ...originalExtraInvoiceLinesSchema.items,
            properties: {
              ...originalExtraInvoiceLinesSchema.items?.properties,
              article: {
                ...originalExtraInvoiceLinesSchema.items?.properties
                  ?.logisticsItems,
                enum: this.props.logisticsItems
                  ? this.props.logisticsItems.map(item => item.ID)
                  : [],
                enumNames: this.props.logisticsItems
                  ? this.props.logisticsItems.map(item => item.Code)
                  : []
              }
            }
          }
        },
        location: {
          ...quoteRequestSchema.properties?.location,
          enum:
            quoteRequest?.locationOptions && quoteRequest.locationOptions.length
              ? [...quoteRequest.locationOptions]
              : (locationSchema.enum as any)
        },
        rawEditorState: {},
        quoteAccept: {},
        summercampDetails: quoteRequest?.summercampDetails
          ? openApiDoc?.components?.schemas?.SummercampDetails
          : {}
      }
    };

    return (
      <>
        {logisticsItems.length ? null : (
          <Alert color="warning">
            Producten kunnen niet worden opgehaald. Zorg voor een werkende
            Exact-koppeling.
          </Alert>
        )}
        <Form
          schema={schema as JSONSchema6}
          uiSchema={{
            ...quoteRequestUiSchema,
            extraInvoiceLines: {
              ...quoteRequestUiSchema.extraInvoiceLines,
              "ui:disabled": !!(quoteRequest && quoteRequest.invoiceFileId)
            },
            locationOptions: {
              ...quoteRequestUiSchema.locationOptions,
              "ui:disabled":
                !quoteRequest ||
                !quoteRequest.eventId ||
                !quoteRequest.program ||
                !quoteRequest.program[0]
            },
            location: {
              ...quoteRequestUiSchema.locationOptions,
              "ui:disabled":
                !quoteRequest ||
                !quoteRequest.eventId ||
                !quoteRequest.program ||
                !quoteRequest.program.length
            }
          }}
          fields={fields}
          widgets={widgets}
          formData={{ ...quoteRequest, rawEditorState: undefined }}
          className="quoteRequestDetailsForm d-flex flex-column"
          onChange={this.onQuoteRequestChange}
          onSubmit={this.onSubmit}
          validate={this.validate}
          liveValidate={true}
          transformErrors={transformErrors}
        >
          <footer>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={!this.props.formDirty}
              >
                {this.props.formDirty
                  ? "Wijzigingen opslaan"
                  : "Wijzigingen opgeslagen"}
              </button>
            </div>
          </footer>
        </Form>
      </>
    );
  }

  private onQuoteRequestChange = ({ formData, errors }: any) => {
    const { quoteRequest } = this.props;

    if (
      !quoteRequest ||
      // strip rawEditorState since that is irrelevant
      // stringify and parse to filter "undefined" property-values on both sides
      // "deepEquals" to ignore property-order
      reactJsonschemaFormUtils.deepEquals(
        JSON.parse(
          JSON.stringify({ ...quoteRequest, rawEditorState: undefined })
        ),
        JSON.parse(JSON.stringify({ ...formData, rawEditorState: undefined }))
      )
    ) {
      return;
    }

    // https://trello.com/c/mjOHN61n/200-slammer-zakelijke-boeking-vink-aan-dan-sjabloon-zakelijk-laden
    if (formData.isBusinessOrder !== quoteRequest.isBusinessOrder) {
      formData.template = formData.isBusinessOrder ? "zakelijk" : "standaard";
    }

    // fix prices
    if (formData.perPersonPriceEx !== quoteRequest.perPersonPriceEx) {
      formData.perPersonPriceIn = formData.perPersonPriceEx * 1.21;
    } else {
      if (formData.perPersonPriceIn !== quoteRequest.perPersonPriceIn) {
        formData.perPersonPriceEx = formData.perPersonPriceIn / 1.21;
      }
    }

    if (!formData.extraInvoiceLines) {
      formData.extraInvoiceLines = [];
    }

    formData.extraInvoiceLines.forEach(
      (invoiceLine: Components.Schemas.InvoiceLine, key: number) => {
        const originalInvoiceLine = quoteRequest.extraInvoiceLines
          ? quoteRequest.extraInvoiceLines[key]
          : undefined;
        if (!originalInvoiceLine) {
          return;
        }

        if (invoiceLine.article !== originalInvoiceLine.article) {
          const logisticsItem = this.props.logisticsItems
            ? this.props.logisticsItems.find(
                item => item.ID === invoiceLine.article
              )
            : null;
          if (logisticsItem) {
            invoiceLine.priceEx = logisticsItem.PriceExcludingVAT;
            invoiceLine.priceIn = logisticsItem.PriceIncludingVAT;
            invoiceLine.vat = logisticsItem.VATCode === "1" ? "low" : "high";
          }
          return;
        }
        const vatMultiplier = invoiceLine.vat === "low" ? 1.06 : 1.21;
        if (invoiceLine.priceEx !== originalInvoiceLine.priceEx) {
          invoiceLine.priceIn = invoiceLine.priceEx * vatMultiplier;
        } else {
          if (
            invoiceLine.priceIn !== originalInvoiceLine.priceIn ||
            invoiceLine.vat !== originalInvoiceLine.vat
          ) {
            invoiceLine.priceEx = invoiceLine.priceIn / vatMultiplier;
          }
        }
      }
    );

    // updated optionalLocations?
    const locationOptions = formData.locationOptions || [];
    difference(quoteRequest.locationOptions, locationOptions).forEach(
      this.deleteHorecaEvent
    );
    difference(locationOptions, quoteRequest.locationOptions || []).forEach(
      this.insertHorecaEvent
    );

    const toBeUpdatedLocations: Array<
      Components.Schemas.Location | undefined
    > = [];

    // new definitive location?
    if (formData.location !== quoteRequest.location) {
      toBeUpdatedLocations.push(formData.location);
      toBeUpdatedLocations.push(quoteRequest.location);
    }

    // update horeca for: new program or new personQuantity
    if (
      formData.personQuantityString !== quoteRequest.personQuantityString ||
      JSON.stringify(formData.program) !== JSON.stringify(quoteRequest.program)
    ) {
      toBeUpdatedLocations.push(quoteRequest.location);
      if (quoteRequest.locationOptions) {
        toBeUpdatedLocations.push(...quoteRequest.locationOptions);
      }
    }

    uniq(toBeUpdatedLocations)
      .filter(location => !!location)
      .forEach(location =>
        this.deleteHorecaEvent(location as Components.Schemas.Location).then(
          () => {
            if (
              formData.location === location ||
              locationOptions.indexOf(location) >= 0
            ) {
              this.insertHorecaEvent(location as Components.Schemas.Location);
            }
          }
        )
      );

    this.setState({
      errors,
      formDirty: true
    });
    this.props.updateFormData({
      ...quoteRequest,
      ...formData,
      // empty strings appear "undefined" and do not update via the api
      program: formData.program || [],
      locationOptions,
      company: formData.company || "",
      phone: formData.phone || "",
      processedBy: formData.processedBy || ""
    });
    this.debouncedPersist(null, true);
  };

  private deleteHorecaEvent = (location: Components.Schemas.Location) => {
    const { quoteRequest } = this.props;
    if (!quoteRequest) {
      return Promise.reject(
        new Error("Missing required context to delete horeca event")
      );
    }
    // look for event horecaCalendar
    // delete it
    return axios
      .delete(
        `/api/google/calendar/${getLocationCalendarId(location)}/${
          quoteRequest._id
        }`
      )
      .then(() => {
        getStore().dispatch(
          Notifications.info({
            autoDismiss: 5,
            message: `De optie is verwijderd uit de agenda van ${location}`,
            position: "tc",
            title: "Agenda-item verwijderd"
          })
        );
      })
      .catch((e: AxiosError) => {
        const { response = { data: {} } } = e;
        const { data } = response;
        const { message = "" } = data;
        getStore().dispatch(
          Notifications.error({
            autoDismiss: 5,
            message,
            position: "tc",
            title: e.message
          })
        );
      });
  };

  private insertHorecaEvent = (location: Components.Schemas.Location) => {
    const { quoteRequest, events } = this.props;
    if (
      !location ||
      !quoteRequest ||
      !quoteRequest.program ||
      !quoteRequest.program[0] ||
      !events
    ) {
      return Promise.reject(
        new Error("Missing required context to insert horeca event")
      );
    }
    const event: gapi.client.calendar.Event | null =
      quoteRequest.calendarId &&
      quoteRequest.eventId &&
      events[quoteRequest.calendarId]
        ? events[quoteRequest.calendarId][quoteRequest.eventId]
        : null;
    if (!event || !event.start) {
      return Promise.reject(
        new Error("Missing required context-event to insert horeca event")
      );
    }

    // add event to horecaCalendar as option
    const programStartEntryData = quoteRequest.program[0].time.split(":");
    const programEndEntryData = quoteRequest.program[
      quoteRequest.program.length - 1
    ].time.split(":");
    const calendarId = getLocationCalendarId(location);
    const programStartMoment = moment(event.start.dateTime)
      .hours(parseInt(programStartEntryData[0], 10))
      .minutes(parseInt(programStartEntryData[1], 10));
    const programEndMoment = moment(event.start.dateTime)
      .hours(parseInt(programEndEntryData[0], 10))
      .minutes(parseInt(programEndEntryData[1], 10));
    if (programEndMoment.isBefore(programStartMoment)) {
      programEndMoment.add(1, "days");
    }

    const horecaEvent: gapi.client.calendar.Event = {
      summary: `${
        calendarId === getLocationCalendarId() ? `${location} ` : ""
      }${quoteRequest.location === location ? "Def: " : "Optie: "}${
        quoteRequest.company ? `${quoteRequest.company} ` : ""
      }${quoteRequest.name} (${quoteRequest.personQuantityString} pax)`,
      description: `Outstanding referentie: ${quoteRequest._id}

E-mail: ${quoteRequest.email}
Telefoon: ${quoteRequest.phone}

Programma:
${quoteRequest.program
  .map(line => `${line.time}: ${line.description}`)
  .join("\n")}

Opmerkingen: ${quoteRequest.comment}
`,
      start: {
        dateTime: programStartMoment.format()
      },
      end: {
        dateTime: programEndMoment.format()
      }
    };
    return axios
      .post(`/api/google/calendar/${calendarId}`, horecaEvent)
      .then(() => {
        getStore().dispatch(
          Notifications.info({
            autoDismiss: 5,
            message: `${location} is op de hoogte gebracht`,
            position: "tc",
            title: "Agenda-item toegevoegd"
          })
        );
      })
      .catch((e: AxiosError) => {
        const { response = { data: {} } } = e;
        const { data } = response;
        const { message = "" } = data;
        getStore().dispatch(
          Notifications.error({
            autoDismiss: 5,
            message,
            position: "tc",
            title: e.message
          })
        );
      });
  };

  private onSubmit = ({ formData }: any) => {
    this.props.persistFormData({
      ...this.props.quoteRequest,
      ...formData
    });
  };

  private validate = (formData: any, errors: any) => {
    if (formData.email && !formData.email.match(/\.[a-z]{2,}$/i)) {
      errors.email.addError(
        "Controleer het laatste stukje van het e-mail adres"
      );
    }
    if (formData.company && !formData.isBusinessOrder) {
      errors.isBusinessOrder.addError(
        "Er is een bedrijfsnaam ingevuld. Deze aanvraag zou zakelijk moeten zijn?"
      );
    }
    return errors;
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
  stateToProps,
  dispatchToProps
)(QuoteRequestForm);
