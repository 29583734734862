import { createBrowserHistory } from "history";
import { NotificationsState } from "react-notification-system-redux";
import { routerMiddleware } from "react-router-redux";
import { AnyAction, applyMiddleware, compose, createStore, Store } from "redux";
import thunkMiddleware from "redux-thunk";
import { IAuthState } from "./auth";
import { IEventState } from "./event";
import { ILogisticsItemState } from "./logisticsItem";
import promiseMiddleware from "./middleware/promise";
import { IQuoteRequestState } from "./quoteRequest";
import rootReducer from "./reducers";
import { ISchemaState } from "./schema";
import { ITemplateState } from "./template";

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();

export enum ActionTypes {
  EVENT_HYDRATE = "EVENT_HYDRATE",
  EVENT_HYDRATE_UPCOMING = "EVENT_HYDRATE_UPCOMING",
  EVENT_SET = "EVENT_SET",
  QUOTEREQUEST_HYDRATE = "QUOTEREQUEST_HYDRATE",
  TEMPLATE_HYDRATE = "TEMPLATE_HYDRATE",
  TEMPLATE_UPDATE = "TEMPLATE_UPDATE"
}

export interface IRootState {
  event: IEventState;
  auth: IAuthState;
  logisticsItem: ILogisticsItemState;
  router: any;
  quoteRequest: IQuoteRequestState;
  sheet: any;
  notifications: NotificationsState;
  schema: ISchemaState;
  template: ITemplateState;
}

let store: Store;

// ======================================================
// Store Enhancers
// ======================================================

export const initStore = (initialState?: IRootState) => {
  store = createStore<IRootState, AnyAction, void, void>(
    rootReducer(),
    initialState,
    compose(
      applyMiddleware(
        // @ts-ignore
        promiseMiddleware,
        thunkMiddleware,
        routerMiddleware(history)
      )
    )
  );
  // @ts-ignore
  store.history = history;

  // @ts-ignore
  if (process.env.NODE_ENV !== "production" && module.hot) {
    // @ts-ignore
    module.hot.accept("./reducers", () => {
      store.replaceReducer(rootReducer());
    });
  }

  return store;
};

export const getStore = () => store;

export default initStore;
