/* tslint:disable */

import moment from "moment-timezone";
import Handlebars from "handlebars/dist/handlebars";
import ucfirst from "locutus/php/strings/ucfirst";
import strip_tags from "locutus/php/strings/strip_tags";
import number_format from "locutus/php/strings/number_format";

Handlebars.registerHelper("toLowerCase", str => (str ? str.toLowerCase() : ""));
Handlebars.registerHelper("formatDate", (datetime, format) =>
  moment(datetime).format(format)
);

const operators = {
  // eslint-disable-next-line
  eq: (l, r) => l == r,
  eqeq: (l, r) => l === r,
  // eslint-disable-next-line
  neq: (l, r) => l != r,
  neqeq: (l, r) => l !== r,
  lt: (l, r) => l < r,
  gt: (l, r) => l > r,
  lte: (l, r) => l <= r,
  gte: (l, r) => l >= r,
  typeof: (l, r) => typeof l == r,
  regexp: (l, r) => l && r && l.match(new RegExp(r)) !== null,
  mod: (l, r) => l % r
};

operators["=="] = operators.eq;
operators["==="] = operators.eqeq;
operators["!="] = operators.neq;
operators["!=="] = operators.neqeq;
operators["<"] = operators.lt;
operators[">"] = operators.gt;
operators["<="] = operators.lte;
operators[">="] = operators.gte;
operators["%"] = operators.mod;

function compare(lvalue, operator, rvalue) {
  if (!operators[operator]) {
    throw new Error(
      `Handlerbars Helper 'compare' doesn't know the operator ${operator}`
    );
  }
  return operators[operator](lvalue, rvalue);
}

Handlebars.registerHelper("compare", function(
  lvalue,
  operator,
  rvalue,
  options
) {
  if (arguments.length < 3) {
    throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
  }

  if (options === undefined) {
    options = rvalue;
    rvalue = operator;
    operator = "eq";
  }

  if (compare(lvalue, operator, rvalue)) {
    return options.fn(this);
  }
  return options.inverse(this);
});

Handlebars.registerHelper("formatFirstName", name => {
  if (!name) {
    return "";
  }

  return ucfirst(name.split(" ")[0]);
});

Handlebars.registerHelper("ucfirst", ucfirst);
Handlebars.registerHelper(
  "price",
  val => `€ ${number_format(val, 2, ",", ".")}`
);
Handlebars.registerHelper("strip_tags", val => (val ? strip_tags(val) : ""));

export default Handlebars;
