import { php } from "locutus";
import { Moment } from "moment";
import moment from "moment-timezone";
import React from "react";
import { Widget, WidgetProps } from "react-jsonschema-form-bs4";
import { Input } from "reactstrap";

import "./index.scss";

const {
  strings: { str_pad }
} = php;

interface INlDateState {
  valueMoment: Moment;
}

class DateTime extends React.Component<WidgetProps, INlDateState> {
  constructor(props: WidgetProps) {
    super(props);
    this.state = {
      valueMoment: moment(props.value || undefined)
    };
  }

  public componentWillReceiveProps(props: WidgetProps) {
    this.setState({
      valueMoment: moment(props.value || undefined)
    });
  }

  public render() {
    const widgetClasses = ["d-flex", "components_form_widgets_dateTime"];
    if (this.state.valueMoment.isBefore(moment())) {
      widgetClasses.push("components_form_widgets_dateTime--passed");
    }

    return (
      <div className={widgetClasses.join(" ")}>
        <span className="d-flex">
          <Input
            type="number"
            className="form-control"
            style={{ width: "64px", display: "inline" }}
            value={this.state.valueMoment.format("DD")}
            onChange={this.onDayChange}
            onBlur={this.onBlur}
          />
        </span>
        <span className="d-flex"> - </span>
        <span className="d-flex">
          <Input
            type="number"
            style={{ width: "64px", display: "inline" }}
            value={this.state.valueMoment.format("MM")}
            onChange={this.onMonthChange}
            onBlur={this.onBlur}
          />
        </span>
        <span className="d-flex"> - </span>
        <span className="d-flex">
          <Input
            type="number"
            style={{ width: "80px", display: "inline" }}
            value={this.state.valueMoment.format("YYYY")}
            onChange={this.onYearChange}
            onBlur={this.onBlur}
          />
        </span>
        <span className="d-flex">
          <Input
            type="select"
            style={{ width: "64px", display: "inline" }}
            value={this.state.valueMoment.format("HH")}
            onChange={this.onHourChange as any}
            onBlur={this.onBlur}
          >
            {Array(24)
              .fill("")
              .map((val, key) => key)
              .map(hour => (
                <option key={`${hour}`}>
                  {str_pad(hour, 2, "0", "STR_PAD_LEFT")}
                </option>
              ))}
          </Input>
        </span>
        <span className="d-flex">:</span>
        <span className="d-flex">
          <Input
            type="select"
            style={{ width: "64px", display: "inline" }}
            value={this.state.valueMoment.format("mm")}
            onChange={this.onMinuteChange as any}
            onBlur={this.onBlur}
          >
            {Array(4)
              .fill("")
              .map((val, key) => key)
              .map(minute => (
                <option key={`${minute}`}>
                  {str_pad(minute * 15, 2, "0", "STR_PAD_LEFT")}
                </option>
              ))}
          </Input>
        </span>
      </div>
    );
  }

  private change = (newValueMoment: Moment) => {
    if (
      newValueMoment.isValid() &&
      newValueMoment.format().indexOf("NaN") === -1
    ) {
      this.props.onChange(newValueMoment.format());
      return;
    }

    window.alert("Ongeldige datum");
  };

  private onHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const target = e.currentTarget;
    this.change(
      this.state.valueMoment.hour(
        parseInt(target.options[target.selectedIndex].value, 10)
      )
    );
  };

  private onMinuteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const target = e.currentTarget;
    this.change(
      this.state.valueMoment.minute(
        parseInt(target.options[target.selectedIndex].value, 10)
      )
    );
  };

  private onDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.change(this.state.valueMoment.date(parseInt(e.target.value, 10)));
  };

  private onMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.change(this.state.valueMoment.month(parseInt(e.target.value, 10) - 1));
  };

  private onYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.change(this.state.valueMoment.year(parseInt(e.target.value, 10)));
  };

  private onBlur = () => {
    this.props.onBlur(this.props.id, this.state.valueMoment.format());
  };
}

export default DateTime as Widget;
