import moment from "moment-timezone";
import * as Sentry from "@sentry/browser";

// tslint:disable-next-line
import "moment/locale/nl";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";

import App from "./App";
import { initStore } from "./store";

// tslint:ignore-next-line
import "./index.scss";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://1d487d5ca56e44b79159241ae0bb81a7@sentry.io/1249027"
  });
}

// Configure global modules
moment.locale("nl");
moment.tz.setDefault("Europe/Amsterdam");

const store: any = initStore();

const envRender = () => {
  render(
    <Provider store={store}>
      <ConnectedRouter history={store.history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
  );
};

envRender();

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept("./App", () => {
    envRender();
  });
}
