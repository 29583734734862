import React, { Component } from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../store";
import { hydrate } from "../../../store/template";
import { Input } from "reactstrap";

interface IStateProps {
  templates?: Components.Schemas.Template[] | null;
}

const stateToProps = (state: IRootState): IStateProps => ({
  templates: state.template.data
});

interface IDispatchProps {
  hydrate: () => void;
}

const dispatchToProps = { hydrate };

interface IOwnProps {
  onLoadNewTemplate: (value: string) => void;
}

type TLoadTemplateComboProps = IStateProps & IDispatchProps & IOwnProps;

class LoadTemplateCombo extends Component<TLoadTemplateComboProps> {
  public componentDidMount(): void {
    this.props.hydrate();
  }

  public render() {
    const { templates } = this.props;
    if (!templates) {
      return null;
    }
    return (
      <Input
        type="select"
        name="select"
        onChange={this.onChange}
        style={{
          backgroundColor: "white",
          border: "1px solid #F1F1F1",
          display: "inline",
          marginLeft: 10,
          width: "auto"
        }}
        value=""
      >
        <option value="">Laad nieuw sjabloon...</option>
        {templates.map(template => (
          <option key={template.title}>{template.title}</option>
        ))}
      </Input>
    );
  }

  private onChange = (e: any) => {
    const select = e.currentTarget;
    const value = select.value || select.options[select.selectedIndex].value;
    this.props.onLoadNewTemplate(value);
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
  stateToProps,
  dispatchToProps
)(LoadTemplateCombo);
