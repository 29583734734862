/* eslint-disable camelcase,jsx-a11y/no-static-element-interactions */

import moment from "moment-timezone";
import { OpenAPIV3 } from "openapi-types";
import React from "react";
import { connect } from "react-redux";
import { push as routePush } from "react-router-redux";
import Spinner from "react-spinkit";
import { Button, Table } from "reactstrap";
import { IRootState } from "../../../store";
import { update } from "../../../store/quoteRequest";

interface IOwnProps {
  canDestroy?: boolean;
  data: Components.Schemas.QuoteRequest[];
  pending?: boolean;
  hydrateMore?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface IStateProps {
  quoteRequestSchema: OpenAPIV3.NonArraySchemaObject;
  events: {
    [calendarId: string]: { [eventId: string]: gapi.client.calendar.Event };
  };
}

const stateToProps = (state: IRootState): IStateProps => {
  return {
    quoteRequestSchema: state.schema.openApiDoc?.components?.schemas
      ?.QuoteRequest as OpenAPIV3.NonArraySchemaObject,
    events: state.event.events
  };
};

interface IDispatchProps {
  update: typeof update;
  routePush: typeof routePush;
}

const dispatchToProps: IDispatchProps = { update, routePush };

type QuoteRequestsGridProps = IStateProps & IDispatchProps & IOwnProps;

class QuoteRequestsGrid extends React.Component<QuoteRequestsGridProps> {
  constructor(props: QuoteRequestsGridProps) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  public onProcessClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const quoteRequestId = e.currentTarget.dataset._id;
    if (!quoteRequestId) {
      return;
    }
    const quoteRequest = this.getQuoteRequestById(quoteRequestId);
    if (quoteRequest) {
      this.props.routePush(`/quoteRequest/${quoteRequest._id}`);
    }
  };

  public onDestroyClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.currentTarget.parentNode || !e.currentTarget.parentNode.parentNode) {
      return;
    }
    const quoteRequestId = (e.currentTarget.parentNode
      .parentNode as HTMLElement).dataset._id;
    if (!quoteRequestId) {
      return;
    }
    const quoteRequest = this.getQuoteRequestById(quoteRequestId);
    if (quoteRequest && window.confirm("Weet je het zeker?")) {
      this.props.update({ ...quoteRequest, status: "destroyed" });
    }
  };

  public getQuoteRequestById(quoteRequestId: string) {
    return this.props.data.find(
      dataQuoteRequest => dataQuoteRequest._id === quoteRequestId
    );
  }

  public render() {
    const {
      canDestroy,
      data,
      events,
      pending,
      quoteRequestSchema
    } = this.props;

    if (!quoteRequestSchema || (pending && data.length === 0)) {
      return (
        <div style={{ margin: 20, marginLeft: 60 }}>
          <Spinner name="pacman" />
        </div>
      );
    }

    return (
      <div>
        <Table style={{ tableLayout: "fixed" }} hover={true}>
          <thead>
            <tr>
              <th style={{ width: 100 }} className="d-none d-lg-table-cell">
                Binnen op
              </th>
              <th style={{ width: 130 }} className="d-none d-md-table-cell">
                Status
              </th>
              <th style={{ width: 70 }} className="d-none d-lg-table-cell">
                Door
              </th>
              <th style={{ width: 130 }} className="d-none d-lg-table-cell">
                Datum gewenst
              </th>
              <th style={{ width: 70 }} className="d-none d-lg-table-cell">
                Aantal
              </th>
              <th style={{ width: 130 }} className="d-none d-md-table-cell">
                Activiteit
              </th>
              <th style={{ width: 200 }}>Naam</th>
              <th>Bedrijfsnaam</th>
              <th style={{ width: 230 }} />
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map(quoteRequest => {
                const event: gapi.client.calendar.Event | null =
                  quoteRequest.calendarId &&
                  quoteRequest.eventId &&
                  events[quoteRequest.calendarId]
                    ? events[quoteRequest.calendarId][quoteRequest.eventId]
                    : null;

                if (!quoteRequest._id || !quoteRequestSchema.properties) {
                  return null;
                }

                // @ts-ignore
                const statusIndex = quoteRequestSchema.properties.status.enum.indexOf(
                  quoteRequest.status || "new"
                );
                return (
                  <tr
                    key={quoteRequest._id}
                    data-_id={quoteRequest._id}
                    style={{ cursor: "pointer" }}
                    onClick={this.onProcessClick}
                  >
                    <td className="d-none d-lg-table-cell">
                      {moment(
                        parseInt(quoteRequest._id.substring(0, 8), 16) * 1000
                      ).format("DD-MM HH:mm")}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {
                        // @ts-ignore
                        quoteRequestSchema.properties.status.enumNames[
                          statusIndex >= 0 ? statusIndex : 0
                        ]
                      }
                    </td>
                    <td className="d-none d-lg-table-cell">
                      {quoteRequest.processedBy}
                    </td>
                    <td className="d-none d-lg-table-cell">
                      {event && event.start
                        ? moment(event.start.dateTime).format("DD-MM-YYYY")
                        : "Onbekend"}
                    </td>
                    <td
                      style={{ textAlign: "center" }}
                      className="d-none d-lg-table-cell"
                    >
                      {quoteRequest.personQuantityString}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {quoteRequest.interested}
                    </td>
                    <td>{quoteRequest.name}</td>
                    <td>{quoteRequest.company}</td>
                    <td>
                      <Button color={this.getButtonColor(quoteRequest)}>
                        <span>
                          <i className="fa fa-eye mr-1" />
                          Openen
                        </span>
                      </Button>
                      {canDestroy && (
                        <Button
                          color="danger ml-1"
                          onClick={this.onDestroyClick}
                        >
                          <i className="fa fa-trash-o mr-1" />
                          Wissen
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {this.renderShowMoreButton()}
      </div>
    );
  }

  private getButtonColor = (quoteRequest: Components.Schemas.QuoteRequest) => {
    switch (quoteRequest.status) {
      case "new":
        return "primary";

      case "waitOnCustomer":
        return "success";

      case "confirmed":
        return "inverse";

      default:
        return "warning";
    }
  };

  private renderShowMoreButton = () => {
    if (!this.props.hydrateMore) {
      return null;
    }

    if (this.props.pending) {
      return (
        <div style={{ margin: 20, marginLeft: 60 }}>
          <Spinner name="pacman" />
        </div>
      );
    }

    return (
      <Button onClick={this.props.hydrateMore}>Toon meer resultaten</Button>
    );
  };
}

export default connect(stateToProps, dispatchToProps)(QuoteRequestsGrid);
