import React, { Component } from "react";
import PropTypes from "prop-types";
import CurrencyInput from "react-currency-input";

class Currency extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number
  };

  static defaultProps = {
    value: 0
  };

  handleChange = (event, mask, floatValue) => {
    this.props.onChange(floatValue);
  };

  render() {
    return (
      <CurrencyInput
        className="form-control"
        value={this.props.value}
        prefix="€ "
        decimalSeparator=","
        thousandSeparator="."
        onChangeEvent={this.handleChange}
      />
    );
  }
}

export default Currency;
