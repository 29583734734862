import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

class AgreeTermsAndConditions extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.bool
  };

  static defaultProps = {
    value: false
  };

  _onChange = e => {
    this.props.onChange(e.target.checked);
  };

  render() {
    return (
      <div style={{ paddingLeft: 20 }}>
        <Input
          id={this.props.id}
          type="checkbox"
          checked={this.props.value}
          onChange={this._onChange}
          required
        />
        <label
          className="control-label field field-boolean"
          htmlFor={this.props.id}
        >
          Ja, ik ga akkoord met de{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/file/d/1oPk9o8mQCxXWXH3r3bcWbib0rjhu_u6p/view"
          >
            algemene voorwaarden
          </a>
          .
        </label>
      </div>
    );
  }
}

export default AgreeTermsAndConditions;
