import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { replace as routeReplace } from "react-router-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarBrand
} from "reactstrap";

import axios from "../../../inc/axios";
import { IRootState } from "../../../store";
import {
  checkAuthenticated,
  logoutExact,
  logoutGoogle
} from "../../../store/auth";

import "./index.scss";

interface IStateProps {
  exact?: number;
}

const stateToProps = (state: IRootState): IStateProps => ({
  exact: state.auth.exact
});

interface IHeaderState {
  dropdownOpen: boolean;
  exactLoggedIn?: boolean;
}

interface IDispatchProps {
  checkAuthenticated: typeof checkAuthenticated;
  logoutExact: typeof logoutExact;
  logoutGoogle: typeof logoutGoogle;
  routeReplace: typeof routeReplace;
}

const dispatchToProps: IDispatchProps = {
  checkAuthenticated,
  logoutExact,
  logoutGoogle,
  routeReplace
};

class Header extends PureComponent<IStateProps & IDispatchProps, IHeaderState> {
  public state: IHeaderState = {
    dropdownOpen: false
  };

  public render() {
    return (
      <Navbar className="app-header navbar mb-1" color="light" light={true}>
        <NavbarBrand href="/">Outstanding Events</NavbarBrand>
        <Dropdown
          isOpen={this.state.dropdownOpen}
          size="sm"
          toggle={this.toggle}
          className="pull-right m-1"
        >
          <DropdownToggle caret={true}>
            <img
              src={`/img/icons/famfamfam/bullet_${
                this.props.exact ? "green" : "red"
              }.png`}
              className="mr-1"
              alt=""
            />
            <img src="/img/icons/famfamfam/cog.png" alt="" />
          </DropdownToggle>
          <DropdownMenu right={true} style={{ overflow: "hidden", width: 200 }}>
            {this.props.exact ? (
              <DropdownItem onClick={this.onExactLogoutClick}>
                <img
                  src="/img/icons/famfamfam/disconnect.png"
                  className="mr-1"
                  alt=""
                />
                Exact ontkoppelen
              </DropdownItem>
            ) : (
              <DropdownItem onClick={this.onExactLoginClick}>
                <img
                  src="/img/icons/famfamfam/connect.png"
                  className="mr-1"
                  alt=""
                />
                Exact koppelen
              </DropdownItem>
            )}
            <DropdownItem href="/templates">
              <img
                src="/img/icons/famfamfam/page_edit.png"
                className="mr-1"
                alt=""
              />
              Bewerk sjablonen
            </DropdownItem>
            <DropdownItem divider={true} />
            <DropdownItem onClick={this.onLogoutClick}>
              <img src="/img/icons/famfamfam/key.png" className="mr-1" alt="" />
              Uitloggen
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Navbar>
    );
  }

  private onExactLoginClick = () => {
    window.location.href = `${axios.defaults.baseURL}/auth/exact`;
  };
  private onExactLogoutClick = () => {
    if (
      window.confirm(
        "Weet je het zeker? Er zullen geen wijzigingen in de boekhouding kunnen worden verwerkt!"
      )
    ) {
      this.props.logoutExact();
    }
  };
  private onLogoutClick = () => {
    this.props.logoutGoogle();
  };

  private toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };
}

// Decorate the form component
export default connect(stateToProps, dispatchToProps)(Header);
