import React from "react";
import PropTypes from "prop-types";
import { JSONSchema4 } from "json-schema";

interface ICheckboxesWidgetProps {
  id: any;
  disabled: any;
  options: any;
  value: any;
  autofocus: any;
  readonly: any;
  onChange: any;
  schema: JSONSchema4;
}

function selectValue(value: any, selected: any, all: any) {
  const at = all.indexOf(value);
  const updated = selected.slice(0, at).concat(value, selected.slice(at));
  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  return updated.sort((a: any, b: any) => all.indexOf(a) > all.indexOf(b));
}

function deselectValue(value: any, selected: any) {
  return selected.filter((v: any) => v !== value);
}

function CheckboxesWidget(props: ICheckboxesWidgetProps) {
  const { id, disabled, options, value, autofocus, readonly, onChange } = props;
  const { enumOptions, enumDisabled, inline } = options;
  return (
    <div className="checkboxes" id={id}>
      {enumOptions.map((option: any, index: any) => {
        const checkboxId = `${id}_${props.schema.title?.replace(
          /[^a-zA-Z0-9]/g,
          "_"
        )}_${index}`;
        const checked = value.indexOf(option.value) !== -1;
        const itemDisabled =
          enumDisabled && enumDisabled.indexOf(option.value) !== -1;
        const disabledCls =
          disabled || itemDisabled || readonly ? "disabled" : "";
        const checkbox = (
          <input
            className="form-check-input"
            type="checkbox"
            id={checkboxId}
            checked={checked}
            disabled={disabled || itemDisabled || readonly}
            autoFocus={autofocus && index === 0}
            onChange={event => {
              const all = enumOptions.map(({ value }: any) => value);
              if (event.target.checked) {
                onChange(selectValue(option.value, value, all));
              } else {
                onChange(deselectValue(option.value, value));
              }
            }}
          />
        );
        return inline ? (
          <div className={`form-check-inline ${disabledCls}`} key={checkboxId}>
            {checkbox}
            <label className="form-check-label" htmlFor={checkboxId}>
              {option.label}
            </label>
          </div>
        ) : (
          <div className={`form-check ${disabledCls}`} key={checkboxId}>
            {checkbox}
            <label className="form-check-label" htmlFor={checkboxId}>
              {option.label}
            </label>
          </div>
        );
      })}
    </div>
  );
}

CheckboxesWidget.defaultProps = {
  autofocus: false,
  options: {
    inline: false
  }
};

if (process.env.NODE_ENV !== "production") {
  CheckboxesWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      enumOptions: PropTypes.array,
      inline: PropTypes.bool
    }).isRequired,
    value: PropTypes.any,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func
  };
}

export default CheckboxesWidget;
