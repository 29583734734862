import axios from "../inc/axios";

import { AnyAction, Dispatch } from "redux";
import { hydrateEvent, hydrateEvents, setEvent } from "./event";
import { ActionTypes, IRootState } from "./index";
import { ObjectId } from "bson";

export function hydrate(forceRefresh: boolean, limit = 20) {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const state = getState();

    if (!forceRefresh && state.quoteRequest.data !== undefined) {
      return;
    }

    dispatch({
      payload: axios
        .post("/api/db/QuoteRequest/find", {
          find: {
            status: {
              $in: ["new", "processing", "waitOnCustomer"]
            }
          },
          limit,
          sort: {
            _id: -1
          }
        })
        .then((res: any) => {
          dispatch(hydrateEvents(res.data) as any);
          return res.data;
        }),
      type: ActionTypes.QUOTEREQUEST_HYDRATE
    });
  };
}

export const hydrateFormData = (quoteRequestId: string) => (
  dispatch: Dispatch
  // getState: () => IRootState
) => {
  dispatch({
    type: "QUOTEREQUEST_HYDRATE_FORM_DATA",
    payload: axios
      .post("/api/db/QuoteRequest/find", {
        find: {
          _id: {
            $ObjectId: quoteRequestId
          }
        }
      })
      .then((res: any) => {
        const result = res.data.pop();
        if (result && result.calendarId && result.eventId) {
          dispatch(hydrateEvent(result.calendarId, result.eventId));
        }
        return result;
      })
  });
};

export function search(query: string) {
  return (dispatch: Dispatch) => {
    const trimmedQuery = query.trim();
    const regex = { $regex: `.*${trimmedQuery}.*`, $options: "i" };
    const $or: any[] = [
      { company: regex },
      { name: regex },
      { email: regex },
      { phone: regex },
      { interested: regex },
      { comment: regex }
    ];
    if (ObjectId.isValid(trimmedQuery)) {
      $or.push({ _id: { $ObjectId: trimmedQuery } });
    }

    dispatch({
      type: "QUOTEREQUEST_SEARCH",
      payload: axios
        .post("/api/db/QuoteRequest/find", {
          find: {
            $and: [
              {
                status: {
                  $ne: "destroyed"
                },
                $or
              }
            ]
          }
        })
        .then((res: any) => {
          dispatch(hydrateEvents(res.data) as any);
          return res.data;
        })
    });
  };
}

export function update(quoteRequest: Components.Schemas.QuoteRequest) {
  if (!quoteRequest._id) {
    return;
  }
  return {
    type: "QUOTEREQUEST_UPDATE",
    quoteRequest,
    payload: axios
      .put(`/api/db/QuoteRequest/${quoteRequest._id}`, quoteRequest)
      .then((res: any) => res.data)
  };
}

export function updateFormData(quoteRequest: Components.Schemas.QuoteRequest) {
  return {
    type: "QUOTEREQUEST_UPDATE_FORM_DATA",
    quoteRequest
  };
}

export function addEvent(quoteRequest: Components.Schemas.QuoteRequest) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "QUOTEREQUEST_UPDATE",
      quoteRequest,
      payload: axios
        .post(`/api/addEvent/${quoteRequest._id}`)
        .then((res: any) => {
          dispatch(
            setEvent(
              res.data.quoteRequest.calendarId,
              res.data.quoteRequest.eventId,
              res.data.event
            )
          );
          dispatch(updateFormData(res.data.quoteRequest));
          return res.data.quoteRequest;
        })
    });
  };
}

export function addQuotePresentation(
  quoteRequest: Components.Schemas.QuoteRequest
) {
  return {
    type: "QUOTEREQUEST_ADD_QUOTE_PRESENTATION",
    payload: axios
      .post(`/api/addQuotePresentation/${quoteRequest._id}`)
      .then((res: any) => ({
        ...quoteRequest,
        ...res.data,
        template: "offerte"
      }))
  };
}

export function addInvoice(quoteRequest: Components.Schemas.QuoteRequest) {
  return {
    type: "QUOTEREQUEST_ADD_INVOICE",
    payload: axios
      .post(`/api/addInvoice/${quoteRequest._id}`)
      .then((res: any) => res.data)
  };
}

export interface IQuoteRequestState {
  pending: boolean;
  data?: Components.Schemas.QuoteRequest[];
  searchResults?: Components.Schemas.QuoteRequest[];
  formData?: Components.Schemas.QuoteRequest;
  hydrateLimit: number;
}

const initialState: IQuoteRequestState = {
  pending: false,
  hydrateLimit: 20
};

export default (
  state: IQuoteRequestState = initialState,
  action: AnyAction
) => {
  let data;
  let searchResults;
  switch (action.type) {
    case ActionTypes.QUOTEREQUEST_HYDRATE:
      switch (action.status) {
        case "pending":
          return { ...state, pending: true, data: undefined };
        case "success":
          return { ...state, pending: false, data: action.payload };
        case "error":
          return { ...state, pending: false };
        default:
      }
      break;

    case "QUOTEREQUEST_HYDRATE_FORM_DATA":
      switch (action.status) {
        case "pending":
          return { ...state, pending: true, formData: undefined };
        case "success":
          return { ...state, pending: false, formData: action.payload };
        case "error":
          return { ...state, pending: false };
        default:
      }
      break;

    case "QUOTEREQUEST_SEARCH":
      switch (action.status) {
        case "pending":
          return { ...state, searchResults: null };
        case "success":
          return { ...state, searchResults: action.payload };
        default:
      }
      break;

    case "QUOTEREQUEST_UPDATE":
      data = state.data || [];
      searchResults = state.searchResults || [];
      switch (action.status) {
        case "pending":
          return {
            ...state,
            formData: action.quoteRequest
          };
        case "success":
          return {
            ...state,
            data:
              action.payload.status === "destroyed"
                ? data.filter(
                    quoteRequest => quoteRequest._id !== action.payload._id
                  )
                : data.map(quoteRequest =>
                    quoteRequest._id === action.payload._id
                      ? action.payload
                      : quoteRequest
                  ),
            formData: action.payload,
            searchResults:
              action.payload.status === "destroyed"
                ? searchResults.filter(
                    quoteRequest => quoteRequest._id !== action.payload._id
                  )
                : searchResults.map(quoteRequest =>
                    quoteRequest._id === action.payload._id
                      ? action.payload
                      : quoteRequest
                  )
          };
        case "error":
        default:
          break;
      }
      break;

    case "QUOTEREQUEST_UPDATE_FORM_DATA":
      data = state.data || [];
      return {
        ...state,
        formData: action.quoteRequest
      };

    case "QUOTEREQUEST_ADD_QUOTE_PRESENTATION":
      switch (action.status) {
        case "pending":
          return {
            ...state,
            pending: true
          };

        case "success":
          return {
            ...state,
            pending: false,
            formData: action.payload
          };

        default:
        case "error":
          return {
            ...state,
            pending: false
          };
      }

    case "QUOTEREQUEST_ADD_INVOICE":
      switch (action.status) {
        case "pending":
          return {
            ...state,
            pending: true
          };

        case "success":
          return {
            ...state,
            pending: false,
            formData: {
              ...state.formData,
              invoiceFileId: action.payload.invoiceFileId
            }
          };

        default:
        case "error":
          return {
            ...state,
            pending: false
          };
      }

    default:
  }
  return state;
};
