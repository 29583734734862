import React, { Component } from "react";
import { connect } from "react-redux";
import { replace as routeReplace } from "react-router-redux";
import Spinner from "react-spinkit";

import axios from "../inc/axios";
import { IRootState } from "../store";
import { checkAuthenticated } from "../store/auth";

interface IStateProps {
  google: boolean;
  pending: boolean;
}
const stateToProps = (state: IRootState): IStateProps => ({
  google: state.auth.google,
  pending: state.auth.pending
});

interface IDispatchProps {
  checkAuthenticated: typeof checkAuthenticated;
  routeReplace: typeof routeReplace;
}

const dispatchToProps: IDispatchProps = { checkAuthenticated, routeReplace };

type LoginProps = IStateProps & IDispatchProps;

class Login extends Component<LoginProps> {
  public componentDidMount() {
    if (!this.props.google) {
      this.props.checkAuthenticated();
      return;
    }
    this.props.routeReplace("/quoterequests");
  }

  public componentWillReceiveProps(props: LoginProps) {
    // ready with auth?
    if (this.props.pending && !props.pending) {
      if (props.google) {
        this.props.routeReplace("/quoterequests");
        return;
      }
      window.location.href = `${
        axios.defaults.baseURL
      }/login?redirect=${encodeURIComponent(window.location.pathname)}`;
    }
  }

  public render() {
    if (this.props.pending) {
      return <Spinner name="pacman" style={{ margin: 20, marginLeft: 60 }} />;
    }

    if (this.props.google) {
      return <div>Redirecting to page</div>;
    }

    return <div>Redirecting to login</div>;
  }
}

// Decorate the form component
export default connect(stateToProps, dispatchToProps)(Login);
