import React, { Component } from "react";
import Notifications, {
  NotificationsState
} from "react-notification-system-redux";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { IRootState } from "./store";
import Main from "./view/container/Main";
import Login from "./view/Login";
import ConfirmPage from "./view/page/Confirm";

// import './App.css';

interface IStateProps {
  notifications: NotificationsState;
}

class App extends Component<IStateProps> {
  public render() {
    return (
      <div>
        <Switch>
          <Route path="/" exact={true} component={Login} />
          <Route path="/confirm/:quoteRequestId" component={ConfirmPage} />
          <Route path="/:module" component={Main} />
        </Switch>
        <Notifications notifications={this.props.notifications} />
      </div>
    );
  }
}

export default withRouter(connect<IStateProps, {}, {}, IRootState>(state => ({
  notifications: state.notifications
}))(App) as any);
