import { Dispatch } from "redux";
import axios from "../inc/axios";
import { ActionTypes, IRootState } from "./index";
import { IPromiseAction } from "./middleware/promise";

interface IHydrateAction extends IPromiseAction<Components.Schemas.Template[]> {
  type: ActionTypes.TEMPLATE_HYDRATE;
}

export function hydrate() {
  return (dispatch: Dispatch<IHydrateAction>, getState: () => IRootState) => {
    const state = getState();

    if (state.template.data !== undefined) {
      return;
    }

    dispatch({
      type: ActionTypes.TEMPLATE_HYDRATE,
      payload: axios
        .post("/api/db/Template/find", {
          sort: {
            title: 1
          }
        })
        .then((res: any) => res.data)
    });
  };
}

interface IUpdateAction extends IPromiseAction<Components.Schemas.Template> {
  type: ActionTypes.TEMPLATE_UPDATE;
  templateId?: string;
}

export function update(template: Components.Schemas.Template): IUpdateAction {
  const method = template._id ? "put" : "post";
  return {
    type: ActionTypes.TEMPLATE_UPDATE,
    templateId: template._id,
    payload: axios
      .request({
        method,
        url: `/api/db/Template${template._id ? `/${template._id}` : ""}`,
        data: template
      })
      .then((res: any) => res.data)
  };
}

export interface ITemplateState {
  data?: null | Components.Schemas.Template[];
}

const defaultState: ITemplateState = {};

export default (
  state: ITemplateState = defaultState,
  action: IUpdateAction | IHydrateAction
): ITemplateState => {
  const { status, type, payload } = action;
  switch (type) {
    case ActionTypes.TEMPLATE_HYDRATE:
      switch (status) {
        case "success":
          return {
            ...state,
            data: payload as Components.Schemas.Template[]
          };
        default:
          return {
            ...state,
            data: null
          };
      }

    case ActionTypes.TEMPLATE_UPDATE:
      const { templateId } = action as IUpdateAction;

      switch (status) {
        case "success":
          if (!state.data) {
            return state;
          }
          const data = (templateId
            ? state.data.map((template: Components.Schemas.Template) =>
                template._id === templateId ? payload : template
              )
            : [...state.data, payload]) as Components.Schemas.Template[];
          return {
            ...state,
            data
          };
      }
      break;
  }
  return state;
};
