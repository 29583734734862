import React from "react";
import { AjvError, ErrorListProps } from "react-jsonschema-form-bs4";
import { Alert } from "reactstrap";

const translatedErrors = {
  "is a required property": "Dit is een verplicht veld",
  "should NOT have fewer than 1 items": "Kies ten minste 1 optie"
};

export function transformErrors(errors: AjvError[]) {
  return errors.map(error => {
    error.message = translatedErrors[error.message]
      ? translatedErrors[error.message]
      : error.message;
    return error;
  });
}

export function ErrorListTemplate(props: ErrorListProps) {
  const { errors } = props;
  return (
    <Alert color="danger">{`Controleer uw invoer: ${
      errors[0].stack.split(": ")[1]
    }`}</Alert>
  );
}
