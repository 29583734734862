import moment from "moment-timezone";
import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import { IRootState } from "../../../store";
import { hydrateUpcoming } from "../../../store/event";
import {
  hydrate as quoteRequestHydrate,
  search
} from "../../../store/quoteRequest";
import { hydrate as schemaHydrate } from "../../../store/schema";
import { hydrateContext as sheetHydrate } from "../../../store/sheet.js";
import QuoteRequestsGrid from "../../../view/components/QuoteRequestsGrid";

interface IStateProps {
  data: Components.Schemas.QuoteRequest[];
  searchResults: Components.Schemas.QuoteRequest[];
  pending: boolean;
  total: number;
  upcomingOrderEvents?: gapi.client.calendar.Event[];
}

const stateToProps = (state: IRootState): IStateProps => ({
  data: state.quoteRequest.data || [],
  searchResults: state.quoteRequest.searchResults || [],
  pending: state.quoteRequest.pending,
  total: 0,
  upcomingOrderEvents: state.event.upcomingOrderEvents
});

interface IDispatchProps {
  hydrateUpcoming: typeof hydrateUpcoming;
  search: typeof search;
  quoteRequestHydrate: typeof quoteRequestHydrate;
  schemaHydrate: typeof schemaHydrate;
  sheetHydrate: typeof sheetHydrate;
}

const dispatchToProps: IDispatchProps = {
  hydrateUpcoming,
  search,
  quoteRequestHydrate,
  schemaHydrate,
  sheetHydrate
};

interface IIndexPageState {
  activeTab: string;
}

type IndexPageProps = IStateProps & IDispatchProps;

export class IndexPage extends React.Component<
  IndexPageProps,
  IIndexPageState
> {
  private queryInput?: HTMLInputElement;
  constructor(props: IndexPageProps) {
    super(props);
    this.state = {
      activeTab: "open"
    };
  }

  public componentDidMount() {
    // always refresh when the component is mounted...
    this.props.hydrateUpcoming();
    this.props.schemaHydrate();
    this.props.quoteRequestHydrate(
      true,
      this.props.data.length ? this.props.data.length : 20
    );
    this.props.sheetHydrate();
  }

  public onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!this.queryInput) {
      return;
    }
    this.props.search(this.queryInput.value);
  };

  public toggle = (tab: "open" | "upcoming" | "search") => {
    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab
        },
        () => {
          if (tab === "search" && this.queryInput) {
            this.queryInput.focus();
          }
        }
      );
    }
  };

  public render() {
    const { upcomingOrderEvents = [] } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div>
              <Nav tabs={true}>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "open" ? "active" : ""}
                    onClick={this.onTabOpenClick}
                  >
                    Open aanvragen
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "search" ? "active" : ""
                    }
                    onClick={this.onTabSearchClick}
                  >
                    Zoeken
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "upcoming" ? "active" : ""
                    }
                    onClick={this.onTabTomorrowClick}
                  >
                    Binnenkort
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="open">
                  <QuoteRequestsGrid
                    data={this.props.data}
                    pending={this.props.pending}
                    hydrateMore={this.hydrateMore}
                    canDestroy={true}
                  />
                </TabPane>
                <TabPane tabId="search">
                  <Navbar>
                    <Nav className="ml-auto" navbar={true}>
                      <NavItem>
                        <form
                          className="form-inline d-flex my-2 my-lg-0"
                          style={{ flex: 1 }}
                          onSubmit={this.onSubmit}
                        >
                          <input
                            className="form-control d-flex"
                            type="text"
                            ref={input => {
                              if (input) {
                                this.queryInput = input;
                              }
                            }}
                            placeholder="zoeken"
                            autoFocus={true}
                          />
                        </form>
                      </NavItem>
                    </Nav>
                  </Navbar>
                  <QuoteRequestsGrid data={this.props.searchResults} />
                </TabPane>
                <TabPane tabId="upcoming">
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardHeader>Binnenkort in de agenda</CardHeader>
                        <CardBody>
                          <ol style={{ listStyle: "none outside" }}>
                            {upcomingOrderEvents.map(event => {
                              const description = `${
                                event.start
                                  ? moment(event.start.dateTime).format(
                                      "D-M HH:mm"
                                    )
                                  : ""
                              } ${event.summary}`;
                              const phone =
                                event.extendedProperties?.private
                                  ?.customerPhone ||
                                event.extendedProperties?.private?.phone ||
                                "";
                              const link =
                                phone.startsWith("+316") ||
                                phone.startsWith("06")
                                  ? ` https://wa.me/${phone
                                      .replace(/^06/, "+316")
                                      .replace(
                                        /\D/g,
                                        ""
                                      )}?text=https%3A%2F%2Fgoo.gl%2Fmaps%2FDvLgox1mZqoWkBCR6`
                                  : "";
                              return (
                                <li key={event.id}>
                                  <label>
                                    {link ? (
                                      <a href={link} target="whatsapp">
                                        {description}
                                      </a>
                                    ) : (
                                      description
                                    )}
                                  </label>
                                </li>
                              );
                            })}
                          </ol>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onTabOpenClick = () => {
    this.toggle("open");
  };

  private onTabSearchClick = () => {
    this.toggle("search");
  };

  private onTabTomorrowClick = () => {
    this.toggle("upcoming");
  };

  private hydrateMore = () => {
    this.props.quoteRequestHydrate(true, this.props.data.length + 20);
  };
}

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
  stateToProps,
  dispatchToProps
)(IndexPage as any);
