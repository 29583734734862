/* eslint-disable react/no-array-index-key */

import { debounce } from "lodash";
import { OpenAPIV3 } from "openapi-types";
import React from "react";
import Form from "react-jsonschema-form-bs4";
import { connect } from "react-redux";
import quoteAcceptUiSchema from "../../../../resources/schemas/QuoteAccept.ui.json";
import { IRootState } from "../../../../store";
import { update, updateFormData } from "../../../../store/quoteRequest";
import widgets from "../../../../view/components/Form/widgets";

// @ts-ignore
/* tslint:disable-next-line */
const reactJsonschemaFormUtils = require("react-jsonschema-form-bs4/lib/utils");

interface IStateProps {
  quoteRequest: Components.Schemas.QuoteRequest;
  quoteAcceptSchema: any;
}

const stateToProps = (state: any) => ({
  quoteRequest: state.quoteRequest.formData,
  quoteAcceptSchema: state.schema.openApiDoc.components.schemas
    .QuoteAccept as OpenAPIV3.NonArraySchemaObject
});

interface IDispatchProps {
  update: (
    quoteRequest: Components.Schemas.QuoteRequest
  ) => Promise<Components.Schemas.QuoteRequest>;
  updateFormData: typeof updateFormData;
}

const dispatchToProps = {
  update,
  updateFormData
};

interface IQuoteAcceptFormState {
  errors?: Error[];
  formDirty: boolean;
}

type IQuoteAcceptFormProps = IStateProps & IDispatchProps;

class QuoteAcceptForm extends React.Component<
  IQuoteAcceptFormProps,
  IQuoteAcceptFormState
> {
  public state: IQuoteAcceptFormState = {
    formDirty: false
  };

  private debouncedPersist: (
    quoteAccept?: Components.Schemas.QuoteAccept | null,
    dirty?: boolean
  ) => void;

  constructor(props: IQuoteAcceptFormProps) {
    super(props);
    this.debouncedPersist = debounce(this.persistFormData, 1000);
  }

  public render() {
    const { quoteAcceptSchema, quoteRequest } = this.props;

    if (!quoteRequest) {
      return null;
    }

    return (
      <Form
        schema={quoteAcceptSchema}
        uiSchema={quoteAcceptUiSchema}
        widgets={widgets}
        className="d-flex flex-column flex-grow-1"
        formData={quoteRequest.quoteAccept || undefined}
        onChange={this.onChange}
      >
        <footer>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={!this.state.formDirty}
            >
              {this.state.formDirty
                ? "Wijzigingen opslaan"
                : "Wijzigingen opgeslagen"}
            </button>
          </div>
        </footer>
      </Form>
    );
  }

  private persistFormData = (
    quoteAccept: Components.Schemas.QuoteAccept | null = null,
    dirty: boolean = false
  ) => {
    if (this.state.errors && this.state.errors.length) {
      return;
    }

    this.props
      .update({
        ...this.props.quoteRequest,
        quoteAccept: quoteAccept || this.props.quoteRequest.quoteAccept
      })
      .then(() => {
        if (dirty || this.state.formDirty) {
          this.setState({ formDirty: false });
        }
      });
  };

  private onChange = ({ formData, errors }: any) => {
    const { quoteRequest } = this.props;
    const { quoteAccept } = quoteRequest;

    // doublecheck for a real change
    if (
      !quoteRequest ||
      // stringify and parse to filter "undefined" property-values on both sides
      // "deepEquals" to ignore property-order
      reactJsonschemaFormUtils.deepEquals(
        JSON.parse(JSON.stringify(quoteAccept)),
        JSON.parse(JSON.stringify(formData))
      )
    ) {
      return;
    }
    this.setState({
      errors,
      formDirty: true
    });
    this.props.updateFormData({
      ...this.props.quoteRequest,
      quoteAccept: {
        ...quoteAccept,
        ...formData
      }
    });
    this.debouncedPersist();
  };
}

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
  stateToProps,
  dispatchToProps as any
)(QuoteAcceptForm);
