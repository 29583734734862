import { reducer as notifications } from "react-notification-system-redux";
import { routerReducer as router } from "react-router-redux";
import { combineReducers } from "redux";
import { reducer as auth } from "./auth";
import { reducer as event } from "./event";
import { IRootState } from "./index";
import logisticsItem from "./logisticsItem";
import quoteRequest from "./quoteRequest";
import schema from "./schema";
import sheet from "./sheet";
import template from "./template";

// @ts-ignore
export const makeRootReducer = () =>
  combineReducers<IRootState>({
    auth,
    event,
    router,
    quoteRequest,
    sheet,
    // @ts-ignore
    notifications,
    schema,
    logisticsItem,
    template
  });

export default makeRootReducer;
