import { AxiosError } from "axios";
import { JSONSchema6 } from "json-schema";
import { php } from "locutus";
import moment from "moment-timezone";
import { OpenAPIV3 } from "openapi-types";
import React, { FormEvent, PureComponent } from "react";
import Form, { FormValidation, ISubmitEvent } from "react-jsonschema-form-bs4";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { push as routePush } from "react-router-redux";
import Spinner from "react-spinkit";
import { Card, CardBody, CardHeader } from "reactstrap";
import axios from "../../../inc/axios";
import { ErrorListTemplate, transformErrors } from "../../../inc/form";
import confirmUiSchema from "../../../resources/schemas/Confirm.ui.json";
import { IRootState } from "../../../store";
import { hydrate as schemaHydrate } from "./../../../store/schema";
import widgets from "./../../components/Form/widgets";

import "./index.scss";

const { number_format } = php.strings;

interface IStateProps {
  google: boolean;
  confirmSchema?: OpenAPIV3.NonArraySchemaObject;
  summercampDetailsSchema?: OpenAPIV3.NonArraySchemaObject;
  quoteRequestSchema?: OpenAPIV3.NonArraySchemaObject;
  quoteAcceptSchema?: OpenAPIV3.NonArraySchemaObject;
}

const stateToProps = (state: IRootState): IStateProps => ({
  google: state.auth.google,
  summercampDetailsSchema: state.schema.openApiDoc?.components?.schemas
    ?.SummercampDetails as OpenAPIV3.NonArraySchemaObject,
  quoteRequestSchema: state.schema.openApiDoc?.components?.schemas
    ?.QuoteRequest as OpenAPIV3.NonArraySchemaObject,
  quoteAcceptSchema: state.schema.openApiDoc?.components?.schemas
    ?.QuoteAccept as OpenAPIV3.NonArraySchemaObject
});

interface IDispatchProps {
  routePush: typeof routePush;
  schemaHydrate: typeof schemaHydrate;
}

const dispatchToProps: IDispatchProps = { routePush, schemaHydrate };

interface IConfirmPageState {
  pending: boolean;
  errorMessage?: string;
  quoteRequest?: Components.Schemas.QuoteRequest;
  event?: gapi.client.calendar.Event;
}

interface IConfirmFormData {
  summercampDetails?: Components.Schemas.SummercampDetails;
  quoteAccept: Components.Schemas.QuoteAccept;
}

class ConfirmPage extends PureComponent<
  IStateProps &
    IDispatchProps &
    RouteComponentProps<{ quoteRequestId: string }>,
  IConfirmPageState
> {
  public state: IConfirmPageState = {
    pending: true
  };

  public componentDidMount() {
    this.props.schemaHydrate();
    const { quoteRequestId } = this.props.match.params;

    if (quoteRequestId === "zomerkamp") {
      this.setState({
        pending: false,
        quoteRequest: {
          email: "",
          interested: "Zomerkamp",
          status: "waitOnCustomer"
        }
      });
      return;
    }

    axios
      .get(`/confirm/${this.props.match.params.quoteRequestId}`)
      .then((res: any) => {
        this.setState({
          pending: false,
          ...res.data
        });
      })
      .catch((error: AxiosError | Error) => {
        const { response, message } = error as AxiosError;
        this.setState({
          pending: false,
          errorMessage:
            response && response.data && response.data.message
              ? response.data.message
              : message
        });
      });
  }

  public render() {
    return (
      <div className="container">
        <div className="my-3">
          <img src="/img/logo.png" alt="Outstanding Events" />
        </div>
        {this.renderCard()}
      </div>
    );
  }

  private onEmptySubmit = (e: FormEvent) => {
    e.preventDefault();
    const { quoteRequest } = this.state;
    if (!quoteRequest) {
      return;
    }
    axios
      .post(`/confirm/${this.props.match.params.quoteRequestId}`)
      .then(() => {
        // res
        this.setState({
          pending: false,
          quoteRequest: {
            ...quoteRequest,
            status: "confirmed"
          }
        });
      });
  };

  private onSubmit = (e: ISubmitEvent<IConfirmFormData>) => {
    const { quoteRequest } = this.state;
    if (!quoteRequest) {
      return;
    }

    const quoteRequestPromise: Promise<Components.Schemas.QuoteRequest> = quoteRequest._id
      ? Promise.resolve({
          ...quoteRequest,
          ...e.formData
        })
      : axios
          .post<Components.Schemas.QuoteRequest>(`/api/db/QuoteRequest`, {
            ...quoteRequest,
            email: e.formData.quoteAccept.email,
            ...e.formData
          })
          .then((res: any) => res.data);

    quoteRequestPromise.then(updatedQuoteRequest =>
      axios
        .post(`/confirm/${updatedQuoteRequest._id}`, updatedQuoteRequest)
        .then(() => {
          // res
          this.setState({
            pending: false,
            quoteRequest: {
              ...quoteRequest,
              status: "confirmed"
            }
          });
        })
    );
  };

  private renderConfirmationBlock() {
    const { quoteRequest } = this.state;
    if (!quoteRequest) {
      return null;
    }
    if (this.props.google) {
      return <Link to="/">Terug naar de slammer</Link>;
    }
    if (quoteRequest.isBusinessOrder) {
      return <div>We gaan er een Outstanding event van maken!</div>;
    }
    return (
      <div>
        <p>
          Bedankt voor je reservering. Je ontvangt binnen enkele minuten een
          bevestigingsmail. Neem deze goed door zodat je niet op de verkeerde
          locatie staat!
        </p>
        <p>
          Let op, deze mail komt regelmatig in de ongewenste mail (spam)
          terecht!
        </p>
      </div>
    );
  }

  private renderCard = () => {
    const {
      quoteRequestSchema,
      summercampDetailsSchema,
      quoteAcceptSchema
    } = this.props;
    const { pending } = this.state;

    if (pending) {
      return (
        <Card>
          <CardHeader>Bezig met laden...</CardHeader>
          <CardBody>
            <Spinner name="pacman" style={{ margin: 20, marginLeft: 60 }} />
          </CardBody>
        </Card>
      );
    }

    if (this.state.errorMessage) {
      switch (this.state.errorMessage) {
        case "Option outdated":
          return (
            <Card>
              <CardHeader>De gevraagde optie is verlopen.</CardHeader>
              <CardBody>
                <p>
                  Een optie is 6 dagen geldig. Neem evt. contact op met ons via{" "}
                  <a href="mailto:info@outstandingevents.nl">
                    info@outstandingevents.nl
                  </a>
                </p>
              </CardBody>
            </Card>
          );

        default:
          return (
            <Card>
              <CardHeader>De gevraagde optie is niet gevonden.</CardHeader>
              <CardBody>
                <p>
                  Het kan zijn dat de optie reeds is verwijderd of gewijzigd.
                </p>
                <p>
                  Neem evt. contact op met ons via{" "}
                  <a href="mailto:info@outstandingevents.nl">
                    info@outstandingevents.nl
                  </a>
                </p>
              </CardBody>
            </Card>
          );
      }
    }

    const { quoteRequest, event } = this.state;
    if (!quoteRequest || !quoteRequestSchema || !quoteAcceptSchema) {
      return null;
    }

    if (quoteRequest.status === "confirmed") {
      return (
        <Card>
          <CardHeader>Bedankt voor je bevestiging</CardHeader>
          <CardBody>{this.renderConfirmationBlock()}</CardBody>
        </Card>
      );
    }

    const properties = quoteRequestSchema.properties as {
      [k: string]: OpenAPIV3.NonArraySchemaObject;
    };

    const quoteType = quoteRequest.isBusinessOrder ? "offerte" : "optie";
    const isSummercamp = quoteRequest.interested === "Zomerkamp";
    const confirmFormSchema: JSONSchema6 = {
      type: "object",
      properties: {},
      required: []
    };
    // stupid typescript
    if (!confirmFormSchema.properties || !confirmFormSchema.required) {
      return null;
    }
    if (
      isSummercamp &&
      confirmFormSchema.properties &&
      confirmFormSchema.required
    ) {
      // @ts-ignore
      confirmFormSchema.properties.summercampDetails = {
        ...summercampDetailsSchema,
        title: "Aanmelden zomerkamp",
        description: " "
      };
      confirmFormSchema.required.push("summercampDetails");
    }

    // @ts-ignore
    confirmFormSchema.properties.quoteAccept = {
      ...quoteAcceptSchema,
      title: "Voor akkoord / factuurgegevens",
      description: " "
    };
    confirmFormSchema.required.push("quoteAccept");

    return (
      <Card>
        <CardHeader>
          {`${
            isSummercamp ? "Zomerkamp" : `Uw ${quoteType}`
          } bij Outstanding Events`}
        </CardHeader>
        {quoteRequest.quotePresentationFileId && (
          <CardBody>
            <iframe
              title="Offerte"
              src={`https://docs.google.com/presentation/d/${quoteRequest.quotePresentationFileId}/embed?start=false&loop=false&delayms=3000`}
              frameBorder="0"
              width="100%"
              height="630"
              allowFullScreen={true}
            />
          </CardBody>
        )}
        <CardBody>
          <h2>Aanvraag details</h2>
          <dl>
            {Object.keys(properties)
              .filter(
                propName =>
                  [
                    "_id",
                    "status",
                    "calendarId",
                    "validUntill",
                    "template",
                    "rawEditorState",
                    "quotePresentationFileId",
                    "invoiceFileId",
                    "quoteAccept",
                    "horecaHighPrice",
                    "horecaLowPrice",
                    "isBusinessOrder",
                    "sentDate",
                    "extraInvoiceLines",
                    "program",
                    "locationOptions",
                    "internalComment",
                    "replyHtml"
                  ].indexOf(propName) === -1 && quoteRequest[propName]
              )
              .reduce(
                (
                  prev: Array<
                    React.ReactElement<HTMLDataElement | HTMLDetailsElement>
                  >,
                  propName
                ) => {
                  const prop = properties[propName];
                  let isHtml = false;
                  let title = prop.title || "";
                  let value;

                  switch (propName) {
                    case "eventId":
                      if (!event || !event.start || !event.end) {
                        return prev;
                      }
                      value = `${moment(event.start.dateTime).format(
                        "DD-MM-YYYY HH:mm"
                      )} tot ${moment(event.end.dateTime).format("HH:mm")}`;
                      break;

                    case "processedBy":
                      value =
                        quoteRequest[propName] === "Team"
                          ? "Outstanding"
                          : quoteRequest[propName];
                      break;

                    case "perPersonPriceEx":
                      value = `€ ${number_format(
                        quoteRequest[propName],
                        2,
                        ",",
                        "."
                      )}  (ex. BTW)`;
                      break;

                    case "perPersonPriceIn":
                      value = `€ ${number_format(
                        quoteRequest[propName],
                        2,
                        ",",
                        "."
                      )}  (inc. BTW)`;
                      break;

                    case "confirmationTextPayment":
                    case "confirmationTextClothing":
                      isHtml = true;
                      title = title.substr(27, title.length - 28);
                      value = quoteRequest[propName];
                      break;

                    case "location":
                      isHtml = true;
                      title = "Locatie"; // strip (definitief)
                      value = quoteRequest[propName];
                      break;

                    default:
                      value = quoteRequest[propName];
                  }

                  if (!value) {
                    return prev;
                  }

                  prev.push(<dt key={`${propName}_dt`}>{title}</dt>);
                  prev.push(
                    isHtml ? (
                      <dd
                        key={`${propName}_dd`}
                        dangerouslySetInnerHTML={{ __html: value }}
                      />
                    ) : (
                      <dd key={`${propName}_dd`}>{value}</dd>
                    )
                  );
                  return prev;
                },
                []
              )}
          </dl>
        </CardBody>
        {quoteRequest.isBusinessOrder || isSummercamp ? (
          <CardBody>
            <Form<IConfirmFormData>
              schema={confirmFormSchema}
              uiSchema={confirmUiSchema}
              className="confirmForm"
              widgets={widgets}
              validate={this.validate}
              onSubmit={this.onSubmit}
              onError={this.onError}
              transformErrors={transformErrors}
              ErrorList={ErrorListTemplate}
            >
              <button className="btn btn-success" type="submit">
                {isSummercamp
                  ? "Hierbij meld ik me aan voor het zomerkamp"
                  : "Hierbij accepteer ik de offerte"}
              </button>
            </Form>
          </CardBody>
        ) : (
          <form method="post" onSubmit={this.onEmptySubmit}>
            <input
              type="submit"
              className="btn btn-primary"
              style={{ cursor: "pointer" }}
              value={
                quoteRequest.isBusinessOrder
                  ? "Offerte accepteren"
                  : "Optie nu bevestigen"
              }
            />
          </form>
        )}
      </Card>
    );
  };

  private onError = () => {
    // errors: Array<{ stack: string }>
    window.scrollTo(0, 0);
  };

  private validate = (formData: IConfirmFormData, errors: FormValidation) => {
    // tslint:disable-next-line:no-console
    if (!formData.quoteAccept.agreeTermsAndConditions) {
      errors.agreeTermsAndConditions.addError(
        "Je dient akkoord te gaan met de algemene voowaarden."
      );
    }
    return errors;
  };
}

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
  stateToProps,
  dispatchToProps
)(ConfirmPage as any);
