import moment from "moment-timezone";
import { OpenAPIV3 } from "openapi-types";
import { AnyAction, Dispatch } from "redux";
import axios from "../inc/axios";
import { IRootState } from "./index";

// make __really_ sure dutch locale is used
moment.locale("nl");
moment.tz.setDefault("Europe/Amsterdam");

export function hydrate() {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const state = getState();
    if (state.schema.openApiDoc?.components?.schemas?.hydrateStarted) {
      return;
    }

    dispatch({
      type: "SCHEMA_HYDRATE",
      payload: axios
        .get<OpenAPIV3.Document>("/openapi.json")
        .then((res: any) => res.data)
    });
  };
}

export interface ISchemaState {
  openApiDoc?: OpenAPIV3.Document | null;
}

const initialState: ISchemaState = {};

let openapi: OpenAPIV3.Document;

function replaceOpenApiRefs(schema: any) {
  if (typeof schema !== "object") {
    return;
  }
  Object.keys(schema).forEach(key => {
    if (typeof schema[key] !== "object") {
      return;
    }
    if (schema[key].$ref) {
      const path = schema[key].$ref.substr(2).split("/");
      let schemaAtPath: any = openapi;
      while (path.length) {
        schemaAtPath = schemaAtPath[path.shift()];
      }
      schema[key] = schemaAtPath;
    }
    replaceOpenApiRefs(schema[key]);
  });
  return schema;
}

export default (state: ISchemaState = initialState, action: AnyAction) => {
  switch (action.type) {
    case "SCHEMA_HYDRATE":
      switch (action.status) {
        case "pending":
          return { ...state, openApiDoc: null };

        case "success":
          openapi = {
            ...action.payload,
            components: {
              ...action.payload.components,
              schemas: {
                ...action.payload.components.schemas,
                QuoteRequest: {
                  ...action.payload.components.schemas.QuoteRequest,
                  properties: {
                    ...action.payload.components.schemas.QuoteRequest
                      .properties,
                    _id: {
                      ...action.payload.components.schemas.QuoteRequest
                        .properties._id,
                      format: undefined
                    },
                    status: {
                      ...action.payload.components.schemas.QuoteRequest
                        .properties.status,
                      enumNames: [
                        "Nieuw",
                        "In behandeling",
                        "Wacht op klant",
                        "Wacht op klant (herinnering verstuurd)",
                        "Verwijderd",
                        "Bevestigd"
                      ]
                    }
                  }
                },
                InvoiceLine: {
                  ...action.payload.components.schemas.InvoiceLine,
                  properties: {
                    ...action.payload.components.schemas.InvoiceLine.properties,
                    vat: {
                      ...action.payload.components.schemas.InvoiceLine
                        .properties.vat,
                      enumNames: ["Hoog (21%)", "Laag (6%)"]
                    }
                  }
                }
              }
            }
          };
          const SummercampDetailsPeriodsItems: any = ((openapi?.components
            ?.schemas?.SummercampDetails as OpenAPIV3.NonArraySchemaObject)
            ?.properties?.periods as OpenAPIV3.ArraySchemaObject).items;
          SummercampDetailsPeriodsItems.enumNames = SummercampDetailsPeriodsItems.enum.map(
            (period: string) => {
              const periodMoment = moment(period, "W YYYY");
              // Week 13 - 15 juli - 19 juli 2019
              return `Week ${periodMoment.format("W")} - ${periodMoment.format(
                "D MMMM"
              )} - ${periodMoment.add(4, "days").format("D MMMM YYYY")} `;
            }
          );
          replaceOpenApiRefs(openapi);
          return {
            ...state,
            openApiDoc: openapi
          };
        default:
      }
      break;
    default:
  }
  return state;
};
