import { Editor } from "@tinymce/tinymce-react";
import { php } from "locutus";
// import { debounce } from "lodash";
import moment from "moment-timezone";
import { OpenAPIV3 } from "openapi-types";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { push as routePush } from "react-router-redux";
import Spinner from "react-spinkit";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  //  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import axios from "../../../inc/axios";
import Handlebars from "../../../inc/handlebars.js";
import { IRootState } from "../../../store";
import { setEvent } from "../../../store/event";
import { hydrate as logisticsItemHydrate } from "../../../store/logisticsItem";
import {
  addInvoice,
  addQuotePresentation,
  hydrateFormData,
  update,
  updateFormData
} from "../../../store/quoteRequest";
import { hydrate as schemaHydrate } from "../../../store/schema";
import { hydrateContext as sheetContextHydrate } from "../../../store/sheet.js";
import { hydrate as templateHydrate } from "../../../store/template";
// import LoadTemplateCombo from "../../../view/components/Editor/LoadTemplateCombo";
import QuoteAcceptForm from "./QuoteAcceptForm";
import QuoteEventForm from "./QuoteEventForm";
import QuoteRequestForm from "./QuoteRequestForm";

import "./index.scss";
import LoadTemplateCombo from "../../components/Editor/LoadTemplateCombo";

const {
  url: { urlencode }
} = php;

interface IStateProps {
  events: {
    [calendarId: string]: { [eventId: string]: gapi.client.calendar.Event };
  };
  exact?: number;
  pending: boolean;
  quoteRequest?: Components.Schemas.QuoteRequest;
  quoteRequestSchema?: OpenAPIV3.NonArraySchemaObject;
  sheetContext: any;
  templates?: Components.Schemas.Template[] | null;
}

const stateToProps = (state: IRootState): IStateProps => ({
  events: state.event.events,
  pending: state.quoteRequest.pending,
  quoteRequest: state.quoteRequest.formData,
  sheetContext: state.sheet.context,
  quoteRequestSchema: state.schema.openApiDoc?.components?.schemas
    ?.QuoteRequest as OpenAPIV3.NonArraySchemaObject,
  templates: state.template.data
});

interface IDispatchProps {
  addInvoice: (quoteRequest: Components.Schemas.QuoteRequest) => Promise<any>;
  addQuotePresentation: (
    quoteRequest: Components.Schemas.QuoteRequest
  ) => Promise<Components.Schemas.QuoteRequest>;
  hydrateFormData: typeof hydrateFormData;
  logisticsItemHydrate: typeof logisticsItemHydrate;
  routePush: typeof routePush;
  schemaHydrate: typeof schemaHydrate;
  setEvent: typeof setEvent;
  sheetContextHydrate: typeof sheetContextHydrate;
  templateHydrate: typeof templateHydrate;
  update: (
    quoteRequest: Components.Schemas.QuoteRequest
  ) => Promise<Components.Schemas.QuoteRequest>;
  updateFormData: typeof updateFormData;
}

const dispatchToProps = {
  addInvoice,
  addQuotePresentation,
  hydrateFormData,
  logisticsItemHydrate,
  routePush,
  schemaHydrate,
  setEvent,
  sheetContextHydrate,
  templateHydrate,
  update,
  updateFormData
};

type TQuoteRequestPageProps = IStateProps &
  IDispatchProps &
  RouteComponentProps<{ quoteRequestId: string }>;

interface IQuoteRequestState {
  activeTab: string;
  errors?: Error[];
  formDirty: boolean;
}

class QuoteRequestPage extends React.Component<
  TQuoteRequestPageProps,
  IQuoteRequestState
> {
  public state: IQuoteRequestState = {
    formDirty: false,
    activeTab: "request"
  };

  // private debouncedPersist: () => void;

  constructor(props: TQuoteRequestPageProps) {
    super(props);
    // this.debouncedPersist = debounce(this.persistFormData as any, 1000);
    this.componentWillReceiveProps(props);
  }

  public componentDidMount() {
    this.props.schemaHydrate();
    this.props.logisticsItemHydrate();
    this.props.sheetContextHydrate();
    this.props.hydrateFormData(this.props.match.params.quoteRequestId);
    this.props.templateHydrate();
  }

  public componentWillReceiveProps(props: TQuoteRequestPageProps) {
    if (!props.templates || !props.quoteRequestSchema) {
      return;
    }
    if (
      props.quoteRequest &&
      (!this.props.quoteRequest ||
        this.props.quoteRequest._id !== props.quoteRequest._id ||
        this.props.templates !== props.templates ||
        this.props.quoteRequestSchema !== props.quoteRequestSchema)
    ) {
      this.setState({
        formDirty: false
      });
    }
  }

  public onSendClick = () => {
    const { quoteRequest } = this.props;
    if (!quoteRequest) {
      return;
    }

    // some e-mails look mangled? Double check for proper mail contents!
    const html = quoteRequest.replyHtml || "";
    const htmlConfirmLinkMatch = html.match(
      /https:\/\/app.outstandingevents.nl\/confirm\/([a-f0-9]{24})/
    );
    if (htmlConfirmLinkMatch && htmlConfirmLinkMatch[1] !== quoteRequest._id) {
      window.alert(
        "Invalide bevestiglink gevonden!\n\nDe e-mail bevat een onjuiste bevestiglink. Ververs de e-mail om een juiste inhoud te garanderen!"
      );
      return;
    }

    if (
      html.indexOf("http://confirm/") >= 0 ||
      html.indexOf('"confirm/') >= 0 ||
      html.indexOf("../confirm/") >= 0
    ) {
      window.alert(
        "Invalide bevestiglink gevonden!\n\nDe e-mail bevat een onjuiste bevestiglink. Ververs de e-mail om een juiste inhoud te garanderen!"
      );
      return;
    }

    if (
      quoteRequest.quotePresentationFileId &&
      html.indexOf(quoteRequest.quotePresentationFileId) === -1
    ) {
      window.alert(
        "Offertelink niet gevonden!\n\nDe e-mail bevat geen link naar de offerte. Ververs de e-mail om een juiste inhoud te garanderen!"
      );
      return;
    }
    axios.post("/api/sendMail", {
      to: quoteRequest.email,
      subject: `${quoteRequest.interested ||
        "Uw activiteit"} bij Outstanding (ref. ${quoteRequest._id})`,
      html
    });
    this.props
      .update({
        ...quoteRequest,
        status: "waitOnCustomer",
        sentDate: moment().format()
      })
      .then(() => {
        this.props.routePush("/");
      });
  };

  public render() {
    const {
      pending,
      quoteRequest,
      quoteRequestSchema,
      sheetContext
    } = this.props;
    if (
      !quoteRequest ||
      !sheetContext ||
      pending ||
      !quoteRequestSchema ||
      !quoteRequestSchema.properties
    ) {
      return (
        <div style={{ margin: 20, marginLeft: 60 }}>
          <Spinner name="pacman" />
        </div>
      );
    }

    if (!quoteRequest) {
      return <div className="main my-1">Aanvraag niet (meer) gevonden</div>;
    }

    return (
      <Container fluid={true}>
        <Nav tabs={true} className="mb-1">
          <NavItem>
            <NavLink className="active">Slammer</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={this.onAgendaClick}>Agenda</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={this.onPlanningClick}>Planning</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={this.onMailClick}>Mail</NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={this.onOfferteClick}
              disabled={!quoteRequest.program || !quoteRequest.program.length}
            >
              Offerte{" "}
              {quoteRequest.quotePresentationFileId && (
                <Button
                  onClick={this.onRemoveQuotePresentationFileIdClick}
                  style={{ lineHeight: 0.8, marginLeft: 8 }}
                  size="sm"
                  color="danger"
                >
                  x
                </Button>
              )}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={this.onInvoiceClick}>
              Factuur
              {quoteRequest.invoiceFileId && (
                <Button
                  onClick={this.onRemoveInvoiceFileIdClick}
                  style={{ lineHeight: 0.8, marginLeft: 8 }}
                  size="sm"
                  color="danger"
                >
                  x
                </Button>
              )}
            </NavLink>
          </NavItem>
          <div className="ml-auto">
            {(quoteRequestSchema.properties.processedBy as any).enum.map(
              (user: Components.Schemas.QuoteRequest["processedBy"]) => {
                if (user) {
                  return (
                    <Button
                      key={user}
                      color={
                        quoteRequest.processedBy === user
                          ? "success"
                          : "primary"
                      }
                      className="ml-auto mr-1"
                      data-user={user}
                      onClick={this.onProcessedByButtonClick}
                    >
                      {user}
                    </Button>
                  );
                }
                return null;
              }
            )}
          </div>
        </Nav>
        <TabContent activeTab="quoteRequest" style={{ flex: 1 }}>
          <TabPane tabId="quoteRequest" style={{ height: "100%" }}>
            <Row>
              <Col md={6}>
                <Card>
                  <CardHeader>
                    <strong>{`Aanvraag ${quoteRequest._id}`}</strong>
                  </CardHeader>
                  <CardBody>
                    <QuoteEventForm
                      possiblyUpdateEditor={this.possiblyUpdateEditor}
                    />
                    <QuoteRequestForm
                      formDirty={this.state.formDirty}
                      persistFormData={this.persistFormData}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>{this.renderRightPanel()}</Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    );
  }

  private onInvoiceClick = () => {
    const { quoteRequest } = this.props;
    if (!quoteRequest) {
      return;
    }
    const openTab = (tabQuoteRequest: Components.Schemas.QuoteRequest) => {
      const invoiceWindow = window.open(
        `https://start.exactonline.nl/docs/SlsEntrySalesInvoice.aspx?BCAction=1&EntryID=%7b${tabQuoteRequest.invoiceFileId}%7d&_Division_=${this.props.exact}`,
        "invoiceWindow"
      );
      if (invoiceWindow) {
        invoiceWindow.focus();
      }
    };

    if (quoteRequest.invoiceFileId) {
      openTab(quoteRequest);
      return;
    }
    if (window.confirm("Wil je NU de factuur maken?")) {
      this.props
        .addInvoice(quoteRequest)
        .then((obj: Components.Schemas.QuoteRequest) => {
          openTab(obj);
        });
    }
  };

  private onOfferteClick = () => {
    const { quoteRequest } = this.props;
    if (!quoteRequest) {
      return;
    }

    function openTab(quotePresentationFileId: string) {
      const slidesWindow = window.open(
        `https://docs.google.com/presentation/d/${urlencode(
          quotePresentationFileId
        )}/edit`,
        "slidesWindow"
      );
      if (slidesWindow) {
        slidesWindow.focus();
      }
    }

    if (quoteRequest.quotePresentationFileId) {
      openTab(quoteRequest.quotePresentationFileId);
      return;
    }
    if (window.confirm("Wil je NU de offerte maken?")) {
      this.props
        .addQuotePresentation(quoteRequest)
        .then((newQuoteRequest: Components.Schemas.QuoteRequest) => {
          this.possiblyUpdateEditor(newQuoteRequest);
          openTab(newQuoteRequest.quotePresentationFileId as string);
        });
    }
  };

  private onMailClick = () => {
    const { quoteRequest } = this.props;
    if (!quoteRequest) {
      return;
    }
    const gmailWindow = window.open(
      `https://mail.google.com/mail/u/0/#search/${urlencode(
        quoteRequest.email
      )}+OR+${urlencode(quoteRequest._id)}`,
      "gmailWindow"
    );
    if (gmailWindow) {
      gmailWindow.focus();
    }
  };

  private onPlanningClick = () => {
    const { events, quoteRequest } = this.props;
    if (!quoteRequest) {
      return;
    }
    const event: gapi.client.calendar.Event | null =
      quoteRequest.calendarId &&
      quoteRequest.eventId &&
      events[quoteRequest.calendarId]
        ? events[quoteRequest.calendarId][quoteRequest.eventId]
        : null;
    const dateMoment = moment(
      event && event.start ? event.start.dateTime : undefined
    );
    const sheetTitle = dateMoment.format("D MMM YY").replace(".", "");
    const sheet = this.props.sheetContext.sheets.find(
      (obj: any) => obj.properties.title === sheetTitle
    );
    const planningWindow = window.open(
      `https://docs.google.com/spreadsheets/d/${
        this.props.sheetContext.spreadsheetId
      }/edit${sheet ? `#gid=${sheet.properties.sheetId}` : ""}`,
      "planningWindow"
    );
    if (planningWindow) {
      planningWindow.focus();
    }
  };

  private onAgendaClick = () => {
    const { events, quoteRequest } = this.props;
    if (!quoteRequest) {
      return;
    }
    const event: gapi.client.calendar.Event | null =
      quoteRequest.calendarId &&
      quoteRequest.eventId &&
      events[quoteRequest.calendarId]
        ? events[quoteRequest.calendarId][quoteRequest.eventId]
        : null;
    const dateMoment = moment(
      event && event.start ? event.start.dateTime : undefined
    );
    const calendarWindow = window.open(
      `https://calendar.google.com/calendar/r/day/${dateMoment.format(
        "YYYY/MM/DD"
      )}`,
      "calendarWindow"
    );
    if (calendarWindow) {
      calendarWindow.focus();
    }
  };

  private persistFormData = (
    quoteRequest: Components.Schemas.QuoteRequest | null = null,
    dirty: boolean = false
  ) => {
    const { errors } = this.state;
    const targetQuoteRequest = quoteRequest || this.props.quoteRequest;
    if ((errors && errors.length) || !targetQuoteRequest) {
      return;
    }

    this.props
      .update({
        ...targetQuoteRequest
      })
      .then(() => {
        if (dirty || this.state.formDirty) {
          this.possiblyUpdateEditor(targetQuoteRequest);
          this.setState({ formDirty: false });
        }
      });
  };

  private getFreshHtml = (
    quoteRequest: Components.Schemas.QuoteRequest,
    templates: Components.Schemas.Template[],
    quoteRequestSchema: OpenAPIV3.NonArraySchemaObject
  ): string => {
    const { events } = this.props;

    if (!templates || !quoteRequestSchema || !quoteRequestSchema.properties) {
      throw Error("Need full hydration before creating editor states");
    }
    const title =
      quoteRequest.template ||
      (quoteRequest.isBusinessOrder ? "zakelijk" : "standaard");
    const template = templates.find(obj => obj.title === title);
    if (!template) {
      return "";
    }
    const event: gapi.client.calendar.Event | null =
      quoteRequest.calendarId &&
      quoteRequest.eventId &&
      events[quoteRequest.calendarId]
        ? events[quoteRequest.calendarId][quoteRequest.eventId]
        : null;
    const templateData = {
      event,
      interested: "Blokarten",
      confirmationTextPayment: (quoteRequestSchema.properties
        .confirmationTextPayment as any).default,
      confirmationTextClothing: (quoteRequestSchema.properties
        .confirmationTextClothing as any).default,
      ...quoteRequest
    };
    return Handlebars.compile(template.html)(templateData);
  };

  private possiblyUpdateEditor = (
    quoteRequest: Components.Schemas.QuoteRequest
  ): void => {
    if (
      this.props.quoteRequestSchema &&
      this.props.templates &&
      ["new", "processing"].indexOf(quoteRequest.status as string) >= 0
    ) {
      // this.setState(
      //   {
      //     editorState: this.getFreshHtml(
      //       quoteRequest,
      //       this.props.templates,
      //       this.props.quoteRequestSchema
      //     )
      //   },
      //   this.debouncedPersist
      // );
    }
  };

  private onProcessedByButtonClick = (e: React.MouseEvent) => {
    const { quoteRequest } = this.props;
    if (!quoteRequest) {
      return;
    }
    this.persistFormData(
      {
        ...quoteRequest,
        status:
          quoteRequest.status === "new" ? "processing" : quoteRequest.status,
        processedBy: (e.target as HTMLButtonElement).dataset
          .user as Components.Schemas.QuoteRequest["processedBy"],
        validUntill: moment()
          .add(quoteRequest.isBusinessOrder ? 10 : 6, "days")
          .format()
      },
      true
    );
  };

  private onConfirmClick = () => {
    const { quoteRequest } = this.props;
    if (!quoteRequest) {
      return;
    }
    this.props.routePush(`/confirm/${urlencode(quoteRequest._id)}`);
  };

  private onLoadNewTemplate = (
    templateTitle: Components.Schemas.QuoteRequest["template"]
  ) => {
    const { templates, quoteRequest, quoteRequestSchema } = this.props;
    if (!templates || !quoteRequest || !quoteRequestSchema) {
      return;
    }
    this.props.updateFormData({
      ...quoteRequest,
      template: templateTitle,
      replyHtml: this.getFreshHtml(
        {
          ...quoteRequest,
          template: templateTitle
        },
        templates,
        quoteRequestSchema
      )
    });
    this.setState({
      formDirty: true
    });
  };

  private onRemoveQuotePresentationFileIdClick = (e: React.MouseEvent) => {
    const { quoteRequest } = this.props;
    if (!quoteRequest) {
      return;
    }
    if (window.confirm("Weet je zeker dat je offerte wilt ontkoppelen?")) {
      this.props.update({
        ...quoteRequest,
        quotePresentationFileId: ""
      });
    }
    e.stopPropagation();
  };

  private onRemoveInvoiceFileIdClick = (e: React.MouseEvent) => {
    const { quoteRequest } = this.props;
    if (!quoteRequest) {
      return;
    }
    if (window.confirm("Weet je zeker dat je de factuur wilt ontkoppelen?")) {
      this.props.update({ ...quoteRequest, invoiceFileId: "" });
    }
    e.stopPropagation();
  };

  private onRefreshEmailClick = () => {
    const { templates, quoteRequest, quoteRequestSchema } = this.props;
    if (!templates || !quoteRequest || !quoteRequestSchema) {
      return;
    }
    this.props.update({
      ...quoteRequest,
      replyHtml: this.getFreshHtml(quoteRequest, templates, quoteRequestSchema)
    });
  };

  private renderRightPanel() {
    const { quoteRequest } = this.props;
    if (!quoteRequest) {
      return null;
    }
    switch (quoteRequest.status) {
      case "waitOnCustomer":
      case "reminded":
        return (
          <Card className="flex-grow-1">
            <CardHeader>
              <strong>Wacht op klant</strong>
            </CardHeader>
            <CardBody>
              <h2 className="d-flex">Reeds verzonden</h2>
              <button
                className="btn btn-warning mt-3"
                onClick={this.onConfirmClick}
              >
                <i className="fa fa-legal mr-1" />
                Zelf bevestigen
              </button>
            </CardBody>
          </Card>
        );

      case "confirmed":
        if (quoteRequest.quoteAccept) {
          return (
            <Card className="flex-grow-1">
              <CardHeader>
                <strong>Bevestiging klant</strong>
              </CardHeader>
              <CardBody>
                <div className="d-flex" style={{ flex: 1, overflow: "hidden" }}>
                  <QuoteAcceptForm />
                </div>
              </CardBody>
            </Card>
          );
        }

        return (
          <Card className="flex-grow-1">
            <CardHeader>
              <strong>Bevestigd door klant</strong>
            </CardHeader>
            <CardBody>
              <div
                className="d-flex align-items-center flex-column"
                style={{ flex: 1 }}
              >
                <h2 className="d-flex">Reeds bevestigd</h2>
              </div>
            </CardBody>
          </Card>
        );

      default:
        return (
          <Card className="flex-grow-1">
            <CardHeader>
              <strong>E-mail </strong>
              <Button
                color="secondary"
                size="sm"
                className="mx-1"
                onClick={this.onRefreshEmailClick}
              >
                Verversen
              </Button>
              <LoadTemplateCombo onLoadNewTemplate={this.onLoadNewTemplate} />
            </CardHeader>
            <CardBody>
              <Editor
                value={quoteRequest.replyHtml}
                init={{
                  relative_urls: false,
                  remove_script_host: false,
                  document_base_url: "https://app.outstandingevents.nl/",
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount"
                  ],
                  toolbar:
                    // eslint-disable-next-line no-multi-str
                    "undo redo | formatselect | bold italic forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help"
                }}
                onEditorChange={replyHtml => {
                  this.props.updateFormData({ ...quoteRequest, replyHtml });
                }}
              />
            </CardBody>
            <CardFooter>
              <div className="d-flex justify-content-end">
                <button className="btn btn-primary" onClick={this.onSendClick}>
                  <i className="fa fa-mail-reply mr-1" />
                  Nu antwoorden
                </button>
              </div>
            </CardFooter>
          </Card>
        );
    }
  }
}

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
  stateToProps,
  dispatchToProps as any
)(QuoteRequestPage as any);
