import axios from "axios";
// import { getStore } from '../store';
// import Notifications from 'react-notification-system-redux';

let apiRoot = process.env.REACT_APP_API_ROOT;

if (!apiRoot) {
  apiRoot =
    process.env.NODE_ENV === "production"
      ? "https://api.outstandingevents.nl"
      : "https://localhost:1024";
}

// weird axios error reported per mail: cookies not sent?
axios.defaults.baseURL = `${apiRoot}`;
axios.defaults.responseType = "json";
axios.defaults.withCredentials = true;

export default axios;
