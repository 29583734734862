import { AnyAction, Dispatch } from "redux";
import axios from "../inc/axios";
import { IRootState } from "./index";

export function hydrate() {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const state = getState();
    if (state.logisticsItem.hydrateStarted) {
      return;
    }

    dispatch({
      type: "LOGISTICS_ITEMS_HYDRATE",
      payload: axios
        .get("/api/exact/getLogisticsItems")
        .then((res: any) => res.data)
    });
  };
}

export interface ILogisticsItem {
  Code: string;
  Description: string;
  ID: string;
  PriceExcludingVAT: number;
  PriceIncludingVAT: number;
  VATCode: "1" | "2" | null; // laag, hoog, nix?
}

export interface ILogisticsItemState {
  hydrateStarted: boolean;
  data?: ILogisticsItem[];
}

const initialState: ILogisticsItemState = {
  hydrateStarted: false
};

export default (
  state: ILogisticsItemState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case "LOGISTICS_ITEMS_HYDRATE":
      switch (action.status) {
        case "pending":
          return { ...state, hydrateStarted: true };

        case "success":
          return { ...state, data: action.payload };
        default:
      }
      break;
    default:
  }
  return state;
};
