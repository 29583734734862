import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import Spinner from "react-spinkit";
import { Button, Table } from "reactstrap";
import { IRootState } from "../../../store";
import { hydrate as templateHydrate } from "../../../store/template";

interface IStateProps {
  templates?: null | Components.Schemas.Template[];
}

const stateToProps = (state: IRootState): IStateProps => ({
  templates: state.template.data
});

interface IDispatchProps {
  templateHydrate: typeof templateHydrate;
}

const dispatchToProps: IDispatchProps = {
  templateHydrate
};

type TemplatesPageProps = IStateProps & IDispatchProps & RouteComponentProps;

class TemplatesPage extends React.Component<TemplatesPageProps> {
  public componentDidMount() {
    this.props.templateHydrate();
  }

  public render() {
    const { templates } = this.props;
    if (!templates) {
      return (
        <div style={{ margin: 20, marginLeft: 60 }}>
          <Spinner name="pacman" />
        </div>
      );
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Table style={{ tableLayout: "fixed" }} hover={true}>
              <thead>
                <tr>
                  <th className="d-none d-lg-table-cell">Sjabloon</th>
                  <th style={{ width: 230 }} />
                </tr>
              </thead>
              <tbody>
                {templates.map(template => {
                  return (
                    <tr key={template._id} data-_id={template._id}>
                      <td>{template.title}</td>
                      <td>
                        <Button color="primary" onClick={this.onOpenClick}>
                          <span>
                            <i className="fa fa-eye mr-1" />
                            Openen
                          </span>
                        </Button>
                        {/*<Button*/}
                        {/*  color="secondary ml-1"*/}
                        {/*  onClick={this.onDestroyClick}*/}
                        {/*>*/}
                        {/*  <i className="fa fa-trash-o mr-1" />*/}
                        {/*  Wissen*/}
                        {/*</Button>*/}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Button color="primary" href="/template">
              Voeg sjabloon toe
            </Button>
          </div>
        </div>
      </div>
    );
  }

  private onOpenClick = (e: React.MouseEvent) => {
    this.props.history.push(
      `/template/${
        ((e.currentTarget.parentNode as HTMLTableCellElement)
          .parentNode as HTMLTableRowElement).dataset._id
      }`
    );
  };
}

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
  stateToProps,
  dispatchToProps
)(TemplatesPage as any);
