import { AnyAction } from "redux";

import axios from "../inc/axios";

export function checkAuthenticated() {
  return {
    type: "CHECK_AUTHENTICATED",
    payload: axios.get("auth/status").then((res: any) => res.data)
  };
}

export function logoutExact() {
  return {
    type: "LOGOUT_EXACT",
    payload: axios.post("auth/exact/logout").then((res: any) => res.data)
  };
}

export function logoutGoogle() {
  return {
    type: "LOGOUT_GOOGLE",
    payload: axios.post("auth/google/logout").then((res: any) => res.data)
  };
}

// ------------------------------------
// Reducer
// ------------------------------------

export interface IAuthState {
  pending: boolean;
  google: boolean;
  exact?: number; // number = division
}

const defaultState: IAuthState = {
  pending: false,
  google: false
};

export function reducer(state = defaultState, action: AnyAction): IAuthState {
  switch (action.type) {
    case "CHECK_AUTHENTICATED":
      switch (action.status) {
        case "pending":
          return { ...state, pending: true, google: false, exact: undefined };

        case "success":
          return {
            ...state,
            pending: false,
            google: action.payload.google,
            exact: action.payload.exact
          };

        default:
        case "error":
          return { ...state, pending: false, google: false, exact: undefined };
      }

    case "LOGOUT_EXACT":
      switch (action.status) {
        case "pending":
          return { ...state, pending: true, exact: undefined };

        case "success":
        default:
        case "error":
          return { ...state, pending: false };
      }

    case "LOGOUT_GOOGLE":
      switch (action.status) {
        case "pending":
          return { ...state, pending: true, google: false, exact: undefined };

        case "success":
        default:
        case "error":
          return { ...state, pending: false };
      }
    default:
  }
  return state;
}
